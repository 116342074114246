import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../features/Toaster/toastslice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RolePermission, FeatureAccess } from "../../pages/ManagerList";
import {
  commonPostservice,
  commonGetService,
  commonPutService,
} from "../../utils/properties";
import { Select } from "antd";

interface DepartmentOptions {
  label: string;
  value: string;
}

interface RoleData {
  id?: string;
  role: string;
  description: string;
  departments?: string[];
  created_date?: string;
  sr_no?: string;
  modified_date?: string;
  role_enabled: boolean;
  role_permissions: RolePermission[];
}

const AddRoles = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  let path = location.pathname.split("/");
  let params = new URLSearchParams(location.search);
  let roleKey = params.get("roleKey");

  const dispatch = useAppDispatch();

  const [rolesData, setRolesData] = useState<RoleData>({
    role: "",
    description: "",
    departments: [],
    role_enabled: true,
    role_permissions: [],
  });
  const [isSave, setIsSave] = useState<boolean>(false);
  const [textFieldError, setTextFieldError] = useState<boolean>(false);
  const [spinner, setSpinner] = useState(true);
  const [deptOptions, setDeptOptions] = useState<DepartmentOptions[]>([]);

  useEffect(() => {
    getRoleDetails();
  }, [roleKey]);

  const getRoleDetails = () => {
    // const initialRolePermissions: string[] = [
    //   "Administrator_access",
    //   "Manager_access",
    //   "Database_managment",
    // ];
    // const initialFeatureCategories: string[] = [
    //   "time-tracker",
    //   "user-activity",
    //   "screenshot",
    // ];
    // const initialFeatureAccess: FeatureAccess = {
    //   full_access: false,
    //   create_access: false,
    //   edit_access: false,
    //   view_access: false,
    //   delete_access: false,
    // };

    // let rolePermissionData: RolePermission[] = initialRolePermissions?.map(
    //   (perName) => {
    //     return {
    //       role_permission_category: perName,
    //       role_permission_enabled: false,
    //       role_feature_category_mapping: initialFeatureCategories?.map(
    //         (cgy) => {
    //           return {
    //             feature_category: {
    //               category_name: cgy,
    //             },
    //             feature_access: JSON.parse(
    //               JSON.stringify(initialFeatureAccess)
    //             ),
    //           };
    //         }
    //       ),
    //     };
    //   }
    // );

    commonGetService(`/api/1.0/role/departments`).then((res) => {
      if (res.status === 200) {
        setDeptOptions(res.data);
      }
      else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
    if (path[1] === "addroles") {
      commonGetService(`/api/1.0/role/view`).then((res) => {
        if (res.status === 200) {
          setRolesData((prevState) => ({
            ...prevState,
            role_permissions: res.data.role_permissions,
          }));
        }
        else {
          dispatch(
            showToast({
              message: "Something went wrong in API.",
              status: "retry",
              visible: true,
            })
          );
        }
      });
      // setRolesData((prevState) => ({
      //   ...prevState,
      //   role_permissions: rolePermissionData,
      // }));
      setSpinner(false);
    } else {
      commonGetService(`/api/1.0/role/view/${roleKey}`).then((res) => {
        if (res.status === 200) {
          // let updatedPermissions = rolePermissionData?.map((item) => {
          //   res?.data?.role_permissions?.map((val: RolePermission) => {
          //     if (
          //       item?.role_permission_category === val?.role_permission_category
          //     ) {
          //       item.id = val?.id;
          //       item.role_permission_enabled = true;
          //       item?.role_feature_category_mapping?.map((oCgy) => {
          //         val?.role_feature_category_mapping?.map((nCgy) => {
          //           if (
          //             oCgy?.feature_category?.category_name ===
          //             nCgy?.feature_category?.category_name
          //           ) {
          //             oCgy.id = nCgy?.id;
          //             oCgy.feature_category.id = nCgy?.feature_category?.id;
          //             for (let oKey in oCgy?.feature_access) {
          //               for (let nKey in nCgy?.feature_access) {
          //                 if (oKey === nKey) {
          //                   oCgy.feature_access[oKey as keyof FeatureAccess] =
          //                     nCgy.feature_access[nKey as keyof FeatureAccess];
          //                 }
          //               }
          //             }
          //           }
          //         });
          //       });
          //     }
          //   });

          //   return item;
          // });

          setRolesData((prevState) => {
            return {
              ...prevState,
              id: res?.data?.id,
              role: res?.data?.role,
              description: res?.data?.description,
              departments: res?.data?.departments,
              created_date: res?.data?.created_date,
              sr_no: res?.data?.sr_no,
              modified_date: res?.data?.modified_date
                ? res?.data?.modified_date
                : "",
              role_enabled: res?.data?.role_enabled,
              role_permissions: res?.data?.role_permissions,
            };
          });
          setSpinner(false);
        } else {
          dispatch(
            showToast({
              message: "Something went wrong",
              status: "retry",
              visible: true,
            })
          );
          setSpinner(false);
        }
      });
    }
  };

  const handleToggle = (category: string) => {
    let updatedPermissions: RolePermission[] = rolesData?.role_permissions?.map(
      (item) => {
        if (item.role_permission_category === category) {
          item.role_permission_enabled = !item.role_permission_enabled;
        }
        return item;
      }
    );

    setRolesData((prevState) => {
      return {
        ...prevState,
        role_permissions: updatedPermissions,
      };
    });
  };

  const handleAccessChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    accesName: string,
    cgyName: string,
    featureName: string
  ) => {
    let updatedPermissions: RolePermission[] = rolesData?.role_permissions?.map(
      (role) => {
        if (role.role_permission_category === accesName) {
          role?.role_feature_category_mapping?.map((cgy) => {
            if (cgy?.feature_category?.category_name === cgyName) {
              if (featureName === "full_access") {
                if (e.target.checked) {
                  for (let key in cgy?.feature_access) {
                    cgy.feature_access[key as keyof FeatureAccess] = true;
                  }
                } else {
                  for (let key in cgy?.feature_access) {
                    cgy.feature_access[key as keyof FeatureAccess] = false;
                  }
                }
              } else {
                for (let key in cgy?.feature_access) {
                  if (key === featureName) {
                    cgy.feature_access[key as keyof FeatureAccess] =
                      !cgy.feature_access[key as keyof FeatureAccess];
                  }
                }
                // Check if all features are checked
                const allFeaturesChecked = Object.entries(cgy.feature_access)
                  .filter(([key]) => key !== "full_access")
                  .every(([key, value]) => value === true);
                if (allFeaturesChecked) {
                  cgy.feature_access.full_access = true;
                } else if (!allFeaturesChecked) {
                  cgy.feature_access.full_access = false;
                }
              }
            }
          });
        }
        return role;
      }
    );

    setRolesData((prevState) => {
      return {
        ...prevState,
        role_permissions: updatedPermissions,
      };
    });
  };
  const handleSave = () => {
    if (handleValidate()) {
      if (path[1] === "addroles") {
        // let selectedPermissions = rolesData?.role_permissions?.filter(
        //   (res) => res.role_permission_enabled
        // );
        let params = {
          ...rolesData,
          role_permissions: rolesData?.role_permissions,
        };
        setIsSave(true);
        commonPostservice(`/api/1.0/role/add`, params).then((res) => {
          setIsSave(false);
          if (res?.data?.message === "Role already exists") {
            dispatch(
              showToast({
                message: res?.message,
                status: "retry",
                visible: true,
              })
            );
          } else {
            dispatch(
              showToast({
                message: "The role has been created successfully.",
                status: "success",
                visible: true,
              })
            );
            Navigate(`/roles`, { state: { reload: true } });
          }
        });
      } else if (path[1] === "editroles") {
        setIsSave(true);
        commonPutService(`/api/1.0/role/update/${roleKey}`, rolesData).then(
          (res) => {
            setIsSave(false);
            dispatch(
              showToast({
                message: "The role has been updated successfully.",
                status: "success",
                visible: true,
              })
            );
            Navigate(`/roles`, { state: { reload: true } });
          }
        );
      }
    }
  };

  const handleValidate = () => {
    let selectedPermissions = rolesData?.role_permissions?.filter(
      (res) => res.role_permission_enabled
    );
    let cgyName = "";
    let accessError = selectedPermissions?.some((res) => {
      let error = res?.role_feature_category_mapping?.every((cgy) => {
        if (
          Object.entries(cgy?.feature_access)?.every(
            ([key, value]) => value === false
          )
        ) {
          cgyName = res?.role_permission_category;
          return true;
        } else {
          return false;
        }
      });
      return error === true;
    });

    if (
      rolesData.role === "" ||
      rolesData.description === "" ||
      rolesData?.departments?.length === 0
    ) {
      setTextFieldError(true);
      dispatch(
        showToast({
          message: "Fill required fields",
          status: "retry",
          visible: true,
        })
      );
      return false;
    } else if (selectedPermissions.length === 0) {
      dispatch(
        showToast({
          message: "Please add atleast one access to continue",
          status: "retry",
          visible: true,
        })
      );
      return false;
    } else if (accessError) {
      dispatch(
        showToast({
          message: `Please select atleast one access from the ${cgyName}`,
          status: "retry",
          visible: true,
        })
      );
      return false;
    }
    return true;
  };

  const handleCancel = () => {
    Navigate("/roles", { state: { reload: true } });
  };
  const handleChange = (value: string[]) => {
    setRolesData({ ...rolesData, departments: value });
    setTextFieldError(false);
  };

  return (
    <div className={`add-roles-wrapper ${spinner && "spinner-wrapper"} `}>
      {!spinner ? (
        <>
          <h4>
            {path[1] === "editroles"
              ? "Edit Roles"
              : path[1] === "viewroles"
                ? "View Roles"
                : "Add Roles"}
          </h4>
          {path[1] !== "viewroles" && <span>Set Role Permissions</span>}
          <Form>
            <Form.Group className="mb-2-5">
              <Form.Label>Role Name</Form.Label>
              <Form.Control
                className={
                  textFieldError && rolesData?.role === "" ? "input-error" : ""
                }
                type="text"
                placeholder="Enter Role Name"
                value={rolesData?.role}
                disabled={path[1] === "viewroles"}
                onChange={(e) => {
                  setRolesData({ ...rolesData, role: e.target.value });
                  setTextFieldError(false);
                }}
              />
            </Form.Group>
            {/* <Form.Group className="mb-2-5">
              <Form.Label>Department</Form.Label>
              <Form.Control
                as="select"
                className={
                  textFieldError && rolesData?.role === "" ? "input-error" : ""
                }
                type="text"
                placeholder=""
                // value={rolesData?.role}
                disabled={path[1] === "viewroles"}
                // onChange={(e) => {
                //   setRolesData({ ...rolesData, role: e.target.value });
                //   setTextFieldError(false);
                // }}
              />
              
            </Form.Group> */}
            <Form.Group className="mb-2-5">
              <Form.Label>Department</Form.Label>
              <Select
                mode="multiple"
                className={
                  textFieldError && rolesData?.departments?.length === 0
                    ? "input-error"
                    : ""
                }
                value={rolesData?.departments}
                // defaultValue={["lucy"]}
                placeholder="Add Department"
                style={{ flex: 1 }}
                disabled={path[1] === "viewroles"}
                // options={[
                //   { value: "UI/UX Design", label: "UI/UX Design" },
                //   { value: "Graphic Design", label: "Graphic Design" },
                //   { value: "Testing", label: "Testing" },
                //   { value: "AWS", label: "AWS" },
                //   { value: "Android Developer", label: "Android Developer" },
                //   { value: "Development", label: "Development" },
                //   { value: "HR", label: "HR" },
                //   { value: "Gaming Developer", label: "Gaming Developer" },
                //   { value: "Yiminghe", label: "yiminghe" },
                //   { value: "jack", label: "Jack" },
                //   { value: "lucy", label: "Lucy" },
                // ]}
                options={deptOptions}
                suffixIcon={<></>}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-2-5 w-100">
              <Form.Label>Description</Form.Label>
              <Form.Control
                className={
                  textFieldError && rolesData?.description === ""
                    ? "input-error"
                    : ""
                }
                type="text"
                placeholder="Describe role including key responsibilities"
                value={rolesData?.description}
                disabled={path[1] === "viewroles"}
                onChange={(e) => {
                  setRolesData({ ...rolesData, description: e.target.value });
                  setTextFieldError(false);
                }}
              />
            </Form.Group>
          </Form>
          <h4 className="table-headings-role">Role Permissions</h4>
          {rolesData.role_permissions.map((category, categoryIndex) => (
            <div
              className="table-wrapper"
              key={category?.role_permission_category}
            >
              <table>
                <thead>
                  <tr>
                    <th className="text-capitalize">
                      {category.role_permission_category?.replace("_", " ")}
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      <Form.Check
                        type="switch"
                        checked={category?.role_permission_enabled}
                        disabled={path[1] === "viewroles"}
                        onChange={() =>
                          handleToggle(category.role_permission_category)
                        }
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {category?.role_feature_category_mapping.map(
                    (role, roleIndex) => (
                      <tr key={role?.feature_category?.category_name}>
                        <td>
                          <div
                            title={role?.feature_category?.category_name}
                            className="text-capitalize"
                          >
                            {role?.feature_category?.category_name?.replace(
                              "-",
                              " "
                            )}
                          </div>
                        </td>
                        {Object.entries(role?.feature_access)?.map(
                          (
                            [featureKey, featureValue]: [string, boolean],
                            ind: number
                          ) => (
                            <td>
                              <Form.Check
                                type="checkbox"
                                className="text-capitalize"
                                id={`${category.role_permission_category}-${role?.feature_category?.category_name}-${featureKey}`}
                                label={
                                  featureKey === "full_access"
                                    ? featureKey?.replace("_", " ")
                                    : featureKey?.replace("_access", " ")
                                }
                                disabled={
                                  !category?.role_permission_enabled ||
                                  path[1] === "viewroles"
                                }
                                checked={featureValue}
                                onChange={(event) =>
                                  handleAccessChange(
                                    event,
                                    category.role_permission_category,
                                    role?.feature_category?.category_name,
                                    featureKey
                                  )
                                }
                              />
                            </td>
                          )
                        )}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          ))}
          {path[1] !== "viewroles" && (
            <div className="d-flex justify-content-end w-100">
              <button className="cancel_button" onClick={handleCancel}>
                Cancel
              </button>
              <button
                className="save_button"
                onClick={handleSave}
                disabled={isSave}
              >
                {path[1] !== "editroles" ? "Save" : "Save Changes"}
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center h-100 crt-loader h-20">
          <Spinner animation="border" />
        </div>
      )}
    </div>
  );
};

export default AddRoles;
