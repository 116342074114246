import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { commonGetService, handleFilterApi } from "../../utils/properties";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Skeleton from "react-loading-skeleton";
import { showToast } from "../../features/Toaster/toastslice";

interface ProductiveDetails {
  emp_id: number;
  emp_name: string;
  productive_time: string;
  unproductive_time: string;
  worked_time: string;
  idle_time: string;
  time_percentage: number;
  designation: string;
}

const ProductivityChart = () => {
  const [mostProductive, setMostProductive] = useState<ProductiveDetails[]>([]);
  const [mostUnproductive, setMostUnproductive] = useState<ProductiveDetails[]>(
    []
  );
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [productiveLoading, setProductiveLoading] = useState<boolean>(false);
  const [unProductiveLoading, setUnProductiveLoading] =
    useState<boolean>(false);

  const filterData = useAppSelector((state) => state.filterData);
  const dispatch = useAppDispatch();
  const { startDate, endDate, empDeviceId, filterType, organization, department } = filterData; // Destructuring the filterData state value

  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInitialLoad(false);
          getMostProductiveList();
          getMostUnproductiveList();

          observer.disconnect();
        } else {
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.25,
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [filterData]);

  // useEffect(() => {
  //   if (!initialLoad) {
  //     getMostProductiveList();
  //     getMostUnproductiveList();
  //   }
  // }, [filterData]);

  const getMostProductiveList = () => {
    setProductiveLoading(true);
    commonGetService(
      `/api/1.0/dashboard/trends/most/productive?${handleFilterApi(startDate, endDate, filterType, organization, empDeviceId, department, false)}`
    ).then((res) => {
      setProductiveLoading(false);
      if (res.status === 200) {
        setMostProductive(res?.data);
      }
      else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  const getMostUnproductiveList = () => {
    setUnProductiveLoading(true);
    commonGetService(
      `/api/1.0/dashboard/trends/most/unproductive?${handleFilterApi(startDate, endDate, filterType, organization, empDeviceId, department, false)}`
    ).then((res) => {
      setUnProductiveLoading(false);
      if (res.status === 200) {
        setMostUnproductive(res?.data);
      }
      else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  return (
    <div className="circle-chart-container" ref={targetRef}>
      <Row className="m-0">
        <Col className="high-prod d-flex flex-column" lg>
          <div className="d-flex justify-content-between common-card-title">
            <h5>Highest % Productivity time</h5>
            <p className="cursor-pointer">Productivity Report</p>
          </div>
          <div className="d-flex flex-row circle-crt w-100 flex-wrap justify-content-center">
            {mostProductive.length || productiveLoading ? (
              mostProductive?.map((res, ind) => (
                <div className="circle-cont">
                  <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    key={ind}
                  >
                    {productiveLoading ? (
                      <>
                        <Skeleton
                          count={1}
                          circle={true}
                          className="skel-chart"
                        />
                        <p className="mb-0 w-50">
                          <Skeleton count={1} />
                        </p>
                        <h6 className="mb-0 w-50">
                          <Skeleton count={1} />
                        </h6>
                      </>
                    ) : (
                      <>
                        <div className="cir-bar">
                          <CircularProgressbar
                            value={res?.time_percentage}
                            text={`${res?.time_percentage}%`}
                            strokeWidth={13}
                            styles={buildStyles({
                              // Rotation of path and trail, in number of turns (0-1)
                              rotation: 0.25,
                              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                              strokeLinecap: "round",
                              // Text size
                              textSize: "1rem",

                              // How long animation takes to go from one percentage to another, in seconds
                              pathTransitionDuration: 0.5,
                              // Colors
                              pathColor: `#81DA9F`,
                              textColor: "#121212",
                              trailColor: "#E7E7E7",
                            })}
                          />
                        </div>
                        <p>{res?.emp_name}</p>
                        <h6>{res?.designation}</h6>
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="d-flex flex-row  w-100 no-data-row">
                <div className="no-data-image-wrapper no-user-image">
                  <Image
                    src="/images/dashboard/user_icon.png"
                    alt="No Data Found"
                  />
                </div>
                <p>No users to show</p>
              </div>
            )}
          </div>
        </Col>
        <Col className="high-prod d-flex flex-column" lg>
          <div className="d-flex justify-content-between common-card-title">
            <h5>Highest % Unproductivity time</h5>
            <p className="cursor-pointer">Productivity Report</p>
          </div>
          <div className="d-flex flex-row circle-crt w-100 flex-wrap justify-content-center">
            {mostUnproductive.length || unProductiveLoading ? (
              mostUnproductive?.map((res, ind) => (
                <div className="circle-cont">
                  <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    key={ind}
                  >
                    {unProductiveLoading ? (
                      <>
                        <Skeleton
                          count={1}
                          circle={true}
                          className="skel-chart"
                        />
                        <p className="mb-0 w-50">
                          <Skeleton count={1} />
                        </p>
                        <h6 className="mb-0 w-50">
                          <Skeleton count={1} />
                        </h6>
                      </>
                    ) : (
                      <>
                        <div className="cir-bar">
                          <CircularProgressbar
                            value={res?.time_percentage}
                            text={`${res?.time_percentage}%`}
                            strokeWidth={13}
                            styles={buildStyles({
                              // Rotation of path and trail, in number of turns (0-1)
                              rotation: 0.25,
                              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                              strokeLinecap: "round",
                              // Text size
                              textSize: "1rem",
                              // How long animation takes to go from one percentage to another, in seconds
                              pathTransitionDuration: 0.5,
                              // Colors
                              pathColor: `#EC6762`,
                              textColor: "#121212",
                              trailColor: "#E7E7E7",
                            })}
                          />
                        </div>
                        <p>{res?.emp_name}</p>
                        <h6>{res?.designation}</h6>
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="d-flex flex-row  w-100 no-data-row">
                <div className="no-data-image-wrapper no-user-image">
                  <Image
                    src="/images/dashboard/user_icon.png"
                    alt="No Data Found"
                  />
                </div>
                <p>No users to show</p>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProductivityChart;
