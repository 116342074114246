import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Image, ProgressBar } from "react-bootstrap";
import Barcharts from "../BarCharts/Barcharts";
import "../../styles/dashboard.scss";
import AppsSkeleton from "../Skeletons/AppsSkeleton";
import { commonGetService, handleFilterApi } from "../../utils/properties";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { showToast } from "../../features/Toaster/toastslice";

export interface InsigtsData {
  department: string;
  total_hours: number;
  productive_hours: number;
  unproductive_hours: number;
  neutral_hours: number;
  idle_hours: number;
}

export interface InsigtsData1 {
  day: string;
  timestamp: string;
  total_hours: number;
  productive_hours: number;
  unproductive_hours: number;
  neutral_hours: number;
  idle_hours: number;
}
export interface UnTracked {
  image: string;
  email: string;
  department: string;
  designation: string;
  employee_id: number;
  employee_code: string;
  employee_name: string;
  reason: string;
  device_id: string;
}
interface WebApps {
  app: string;
  time: string;
  percentage: number;
}

export interface ProductiveDetailsProps {
  displayBarChart: boolean;
}

const ProductiveDetails: React.FC<ProductiveDetailsProps> = ({
  displayBarChart,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [productiveLoading, setProductiveLoading] = useState<boolean>(false);
  const [unProductiveLoading, setUnProductiveLoading] =
    useState<boolean>(false);
  const [neutralLoading, setNeutralLoading] = useState<boolean>(false);
  const [trackedLoading, setTrackedLoading] = useState<boolean>(false);
  const [unTrackedUsers, setUntrackedUsers] = useState<UnTracked[]>([]);
  const [initialload, setInitialLoad] = useState<boolean>(true);
  const [productiveApps, setProductiveApps] = useState<WebApps[]>([]);
  const [unProductiveApps, setUnproductiveApps] = useState<WebApps[]>([]);
  const [neutralApps, setNeutralApps] = useState<WebApps[]>([]);
  const [insightsData, setInsightsData] = useState<InsigtsData[]>([]);
  const [insightsLoading, setInsightsLoading] = useState<boolean>(false);

  const targetRef = useRef<HTMLDivElement>(null);
  const filterData = useAppSelector((state) => state.filterData);
  const dispatch = useAppDispatch();
  const {
    startDate,
    endDate,
    empDeviceId,
    filterType,
    organization,
    department,
  } = filterData; // Destructuring the filterData state value

  const checkDate = () => {
    let today = moment(new Date()).format("L");
    let yesterday = moment(moment().subtract(1, "day").toDate()).format("L");
    let fromDate = moment(startDate).format("L");
    let toDate = moment(endDate).format("L");

    return (
      (today === fromDate && today === toDate) ||
      (yesterday === fromDate && yesterday === toDate)
    );
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInitialLoad(false);
          getUntrackedUsersList();
          getProductiveWebApps();
          getUnproductiveWebApps();
          getNeutralWebApps();
          let checks = checkDate();

          if (!checks) {
            getInsightsData();
          }
          observer.disconnect();
        } else {
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.25,
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    // Cleanup function to disconnect the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, [filterData, displayBarChart]);

  // useEffect(() => {
  //   if (!initialload) {
  //     getProductiveWebApps();
  //     getUnproductiveWebApps();
  //     getNeutralWebApps();
  //     getUntrackedUsersList();
  //   }
  //   let checks = checkDate();

  //   if (!checks) {
  //     getInsightsData();
  //   }

  //   return () => {
  //     setInsightsData([]);
  //   };
  // }, [filterData, displayBarChart]);

  const getUntrackedUsersList = () => {
    setTrackedLoading(true);
    commonGetService(
      `/api/1.0/dashboard/users/untracked?${handleFilterApi(
        startDate,
        endDate,
        filterType,
        organization,
        empDeviceId,
        department,
        false
      )}`
    ).then((res) => {
      setTrackedLoading(false);
      if (res.status === 200) {
        setUntrackedUsers(res?.data);
      }
      else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  const getProductiveWebApps = () => {
    setProductiveLoading(true);
    commonGetService(
      `/api/1.0/dashboard/app/productive?${handleFilterApi(
        startDate,
        endDate,
        filterType,
        organization,
        empDeviceId,
        department,
        false
      )}`
    ).then((res) => {
      setProductiveLoading(false);
      if (res.status === 200) {
        setProductiveApps(res?.data);
      }
      else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  const getUnproductiveWebApps = () => {
    setUnProductiveLoading(true);
    commonGetService(
      `/api/1.0/dashboard/app/unproductive?${handleFilterApi(
        startDate,
        endDate,
        filterType,
        organization,
        empDeviceId,
        department,
        false
      )}`
    ).then((res) => {
      setUnProductiveLoading(false);
      if (res.status === 200) {
        setUnproductiveApps(res?.data);
      }
      else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  const getNeutralWebApps = () => {
    setNeutralLoading(true);
    commonGetService(
      `/api/1.0/dashboard/app/neutral?${handleFilterApi(
        startDate,
        endDate,
        filterType,
        organization,
        empDeviceId,
        department,
        false
      )}`
    ).then((res) => {
      setNeutralLoading(false);
      if (res.status === 200) {
        setNeutralApps(res?.data);
      }
      else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  const getInsightsData = () => {
    if (filterType !== "employee" && !displayBarChart) {
      setInsightsLoading(true);
      commonGetService(
        `/api/1.0/dashboard/department/insights?${handleFilterApi(
          startDate,
          endDate,
          filterType,
          organization,
          empDeviceId,
          department,
          false
        )}`
      ).then((res) => {
        setInsightsLoading(false);
        if (res.status === 200) {
          let formatedData = res?.data?.map((val: InsigtsData) => {
            return {
              Department: val?.department,
              Productive: val?.productive_hours,
              Unproductive: val?.unproductive_hours,
              Idle: val?.idle_hours,
              Neutral: val?.neutral_hours,
              total_hours: val?.total_hours,
            };
          });
          setInsightsData(formatedData);
        }
        else {
          dispatch(
            showToast({
              message: "Something went wrong in API.",
              status: "retry",
              visible: true,
            })
          );
        }
      });
    } else if (!displayBarChart) {
      setInsightsLoading(true);
      commonGetService(
        `/api/1.0/dashboard/productivity/insights?${handleFilterApi(
          startDate,
          endDate,
          filterType,
          organization,
          empDeviceId,
          department,
          false
        )}`
      ).then((res) => {
        setInsightsLoading(false);
        if (res.status === 200) {
          let formatedData = res?.data?.map((val: InsigtsData1) => {
            return {
              day: val?.day,
              timestamp: val?.timestamp,
              Productive: val?.productive_hours,
              Unproductive: val?.unproductive_hours,
              Idle: val?.idle_hours,
              Neutral: val?.neutral_hours,
              total_hours: val?.total_hours,
            };
          });
          setInsightsData(formatedData);
        }
        else {
          dispatch(
            showToast({
              message: "Something went wrong in API.",
              status: "retry",
              visible: true,
            })
          );
        }
      });
    }
  };

  return (
    <div
      className={`d-flex w-100 prod-cont flex-wrap ${filterType === "employee" ? "single-user-reverse" : ""
        }`}
      ref={targetRef}
    >
      {!displayBarChart && filterType !== "employee" && (
        <div
          className={`bar-chart-parent styled-scroll-hor  ${insightsData.length > 5 ? "line-overflow" : ""
            }}`}
        >
          <Barcharts
            insightsData={insightsData}
            chartLoading={insightsLoading}
          />
        </div>
      )}
      {filterType !== "employee" && (
        <>
          <div
            className={`never-td-ctr ${displayBarChart ? "single-day" : ""}`}
          >
            <div className="never-tracked d-flex flex-column h-100">
              <h5>Haven’t Tracked Yet</h5>
              <div className="ntd-table">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Designation</th>
                      <th>Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trackedLoading ? (
                      Array.from({ length: 5 })?.map((res, i) => (
                        <tr key={i}>
                          <td>
                            <Skeleton className="w-75" count={1} />
                          </td>
                          <td>
                            <Skeleton className="w-75" count={1} />
                          </td>
                          <td>
                            <Skeleton className="w-75" count={1} />
                          </td>
                        </tr>
                      ))
                    ) : unTrackedUsers?.length ? (
                      unTrackedUsers
                        ?.filter?.((val, i) => i < 5)
                        ?.map((res, ind) => (
                          <tr key={ind}>
                            <td>{res?.employee_name}</td>
                            <td>{res?.designation}</td>
                            <td>{res?.reason}</td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td
                          className="no-data d-flex flex-column justify-content-center align-items-center"
                          rowSpan={5}
                        >
                          <Image
                            src="/images/dashboard/user_icon.png"
                            alt="No Data Found"
                          />
                          <p className="m-0">No users to show</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {unTrackedUsers.length > 5 && (
                <div className="table-rdk cursor-pointer">
                  <p>{`+${unTrackedUsers?.length - 5} Peoples`}</p>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <div
        className={`web-app-card d-flex flex-column ${displayBarChart ? "single-day" : ""
          }${filterType === "employee" ? "single-user-timeline" : ""}`}
      >
        <div className="card-title">
          <h5>Top used productive websites and apps</h5>
        </div>{" "}
        {productiveLoading ? (
          <div className="skeleton-list-apps">
            <AppsSkeleton count={5} />
          </div>
        ) : (
          <>
            {productiveApps.length ? (
              productiveApps?.map((res, ind) => (
                <div className="d-flex flex-row app-ds-ctr w-100" key={ind}>
                  <div className="fldr-icon">
                    <Image
                      src="/images/dashboard/folder_icon.svg"
                      alt="Folder"
                    />
                  </div>
                  <div className="app-ptg d-flex flex-column pr-apps">
                    <div className="d-flex flex-row justify-content-between">
                      <p className="app-name" title={res?.app}>{res?.app}</p>
                      <p className="app-hours">{res?.time}</p>
                    </div>
                    <div className="app-pg-bar">
                      <ProgressBar
                        now={res?.percentage}
                        variant={
                          ind === 0
                            ? "green-one"
                            : ind === 1
                              ? "green-two"
                              : ind === 2
                                ? "green-three"
                                : ind === 3
                                  ? "green-four"
                                  : "green-five"
                        }
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="d-flex flex-row  w-100 no-data-row">
                <div className="no-data-image-wrapper">
                  <Image src="/images/dashboard/folder_icon.svg" alt="Folder" />
                </div>
                <p>No Apps to show</p>
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={`web-app-card d-flex flex-column ${displayBarChart ? "single-day" : ""
          }`}
      >
        <div className="card-title">
          <h5>Top used unproductive websites and apps</h5>
        </div>
        {unProductiveLoading ? (
          <div className="skeleton-list-apps">
            <AppsSkeleton count={5} />
          </div>
        ) : (
          <>
            {unProductiveApps.length ? (
              unProductiveApps?.map((res, ind) => (
                <div className="d-flex flex-row app-ds-ctr w-100">
                  <div className="fldr-icon">
                    <Image
                      src="/images/dashboard/folder_icon.svg"
                      alt="Folder"
                    />
                  </div>
                  <div className="app-ptg d-flex flex-column">
                    <div className="d-flex flex-row justify-content-between">
                      <p className="app-name" title={res?.app}>{res?.app}</p>
                      <p className="app-hours">{res?.time}</p>
                    </div>
                    <div className="app-pg-bar">
                      <ProgressBar
                        now={res?.percentage}
                        variant={
                          ind === 0
                            ? "red-one"
                            : ind === 1
                              ? "red-two"
                              : ind === 2
                                ? "red-three"
                                : ind === 3
                                  ? "red-four"
                                  : "red-five"
                        }
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="d-flex flex-row  w-100 no-data-row">
                <div className="no-data-image-wrapper">
                  <Image src="/images/dashboard/folder_icon.svg" alt="Folder" />
                </div>
                <p>No Apps to show</p>
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={`web-app-card d-flex flex-column ${displayBarChart ? "single-day" : ""
          }`}
      >
        <div className="card-title">
          <h5>Top used neutral websites and apps</h5>
        </div>
        {neutralLoading ? (
          <div className="skeleton-list-apps">
            <AppsSkeleton count={5} />
          </div>
        ) : (
          <>
            {neutralApps.length ? (
              neutralApps?.map((res, ind) => (
                <div className="d-flex flex-row app-ds-ctr w-100" key={ind}>
                  <div className="fldr-icon">
                    <Image
                      src="/images/dashboard/folder_icon.svg"
                      alt="Folder"
                    />
                  </div>
                  <div className="app-ptg d-flex flex-column">
                    <div className="d-flex flex-row justify-content-between w-100">
                      <p className="app-name" title={res?.app}>{res?.app}</p>
                      <p className="app-hours">{res?.time}</p>
                    </div>
                    <div className="app-pg-bar">
                      <ProgressBar
                        now={res?.percentage}
                        variant={ind === 0 ? "blue-one" : "blue-two"}
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="d-flex flex-row  w-100 no-data-row">
                <div className="no-data-image-wrapper">
                  <Image src="/images/dashboard/folder_icon.svg" alt="Folder" />
                </div>
                <p>No Apps to show</p>
              </div>
            )}
          </>
        )}
      </div>
      {!displayBarChart && filterType === "employee" && (
        <div
          className={`bar-chart-parent  ${insightsData.length > 5 ? "line-overflow" : ""
            } single-user styled-scroll-hor`}
        >
          <Barcharts
            insightsData={insightsData}
            chartLoading={insightsLoading}
          />
        </div>
      )}
    </div>
  );
};

export default ProductiveDetails;
