import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { showToast } from "../../features/Toaster/toastslice";
import { useAppDispatch } from "../../app/hooks";
import DeletePopup from "../AddManager/DeletePopup";
import { roleContext } from "../../pages/roles";
import { RolesData } from "../../pages/ManagerList";
import { commonDeleteService, commonPutService } from "../../utils/properties";

const RolesListTable = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownRoleId, setDropdownRoleId] = useState<string | null>(null);
  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);
  const [deleConfirm, setDeleteConfirm] = useState<string>("");
  const [list, setList] = useState<RolesData[] | null>([]);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const Navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const rolesList: RolesData[] | null = useContext(roleContext);

  useEffect(() => {
    setList(rolesList);
  }, [rolesList]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target as Node)
      ) {
        setShowDropdown(false);
        setDropdownRoleId(null);
      }
    };

    if (showDropdown) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [showDropdown]);

  const handleDropdown = (
    e: React.MouseEvent<Element, MouseEvent>,
    role: RolesData
  ) => {
    e.stopPropagation();

    if (dropdownRoleId === role?.id) {
      setShowDropdown(false);
      setDropdownRoleId(null);
    } else {
      setShowDropdown(true);
      setDropdownRoleId(role?.id);
    }
  };

  const addRole = () => {
    Navigate("/addroles");
  };

  const handleNavigation = (
    e: React.MouseEvent<Element, MouseEvent>,
    route: string,
    id: string
  ) => {
    e.stopPropagation();
    Navigate(`/${route}?roleKey=${id}`, { state: {} });
  };

  const handleDelete = (
    e: React.MouseEvent<Element, MouseEvent>,
    id: string
  ) => {
    e.stopPropagation();
    setDeleteModalShow(true);
    setDeleteConfirm(id);
  };

  const deleteConfirmation = (val: boolean, action: string) => {
    if (action === "Yes") {
      commonDeleteService(`/api/1.0/role/delete/${deleConfirm}`).then((res) => {
        if (res.status === 200) {
          let stateValue = location.state?.reload;

          setDeleteModalShow(false);
          setDeleteConfirm("");
          dispatch(
            showToast({
              message: "Role deleted successfully",
              status: "success",
              visible: true,
            })
          );
          Navigate(`/roles`, { state: { reload: !stateValue } });
        } else {
          dispatch(
            showToast({
              message: "Something went wrong",
              status: "retry",
              visible: true,
            })
          );
        }
      });
    } else {
      setDeleteModalShow(false);
    }
  };

  const handleActivate = (
    event: React.ChangeEvent<HTMLInputElement>,
    rId: string
  ) => {
    commonPutService(`/api/1.0/role/toggle/${rId}`).then((res) => {
      if (res.status === 200) {
        let stateValue = location.state?.reload;
        Navigate(`/roles`, { state: { reload: !stateValue } });
      } else {
        dispatch(
          showToast({
            message: "Something went wrong",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <h4>List Of Roles</h4>
        <button className="add_role_button" onClick={addRole}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 6L12 18"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M18 12L6 12"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>{" "}
          Add Role
        </button>
      </div>
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Sr.no</th>
              <th>Role</th>
              <th>Description</th>
              <th>Modified Date</th>
              <th>Created Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {list?.map((role, index) => (
              <tr key={index}>
                <td className="serial_no">{role?.sr_no}</td>
                <td className="text-capitalize">{role?.role}</td>
                <td>
                  <div className="description">{role?.description}</div>
                </td>
                <td>{role?.modified_date}</td>
                <td>{role?.created_date}</td>
                <td>
                  <Form.Check
                    type="switch"
                    id={`custom-switch-${index}`}
                    checked={role?.role_enabled}
                    onChange={(e) => {
                      handleActivate(e, role?.id);
                    }}
                  />
                </td>
                <td
                  onMouseLeave={() => {
                    setShowDropdown(false);
                    setDropdownRoleId(null);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    className={`${role?.role_enabled ? "active" : "disabled"}`}
                    onClick={(e) =>
                      role?.role_enabled && handleDropdown(e, role)
                    }
                  >
                    <path
                      d="M9.5 12C9.5 12.2652 9.39464 12.5196 9.20711 12.7071C9.01957 12.8946 8.76522 13 8.5 13C8.23478 13 7.98043 12.8946 7.79289 12.7071C7.60536 12.5196 7.5 12.2652 7.5 12C7.5 11.7348 7.60536 11.4804 7.79289 11.2929C7.98043 11.1054 8.23478 11 8.5 11C8.76522 11 9.01957 11.1054 9.20711 11.2929C9.39464 11.4804 9.5 11.7348 9.5 12ZM13.5 12C13.5 12.2652 13.3946 12.5196 13.2071 12.7071C13.0196 12.8946 12.7652 13 12.5 13C12.2348 13 11.9804 12.8946 11.7929 12.7071C11.6054 12.5196 11.5 12.2652 11.5 12C11.5 11.7348 11.6054 11.4804 11.7929 11.2929C11.9804 11.1054 12.2348 11 12.5 11C12.7652 11 13.0196 11.1054 13.2071 11.2929C13.3946 11.4804 13.5 11.7348 13.5 12ZM17.5 12C17.5 12.2652 17.3946 12.5196 17.2071 12.7071C17.0196 12.8946 16.7652 13 16.5 13C16.2348 13 15.9804 12.8946 15.7929 12.7071C15.6054 12.5196 15.5 12.2652 15.5 12C15.5 11.7348 15.6054 11.4804 15.7929 11.2929C15.9804 11.1054 16.2348 11 16.5 11C16.7652 11 17.0196 11.1054 17.2071 11.2929C17.3946 11.4804 17.5 11.7348 17.5 12Z"
                      fill={`${role?.role_enabled ? "#969696" : "#E7E7E7"}`}
                    />
                    <path
                      d="M2.5 12C2.5 7.286 2.5 4.929 3.964 3.464C5.43 2 7.786 2 12.5 2C17.214 2 19.571 2 21.035 3.464C22.5 4.93 22.5 7.286 22.5 12C22.5 16.714 22.5 19.071 21.035 20.535C19.572 22 17.214 22 12.5 22C7.786 22 5.429 22 3.964 20.535C2.5 19.072 2.5 16.714 2.5 12Z"
                      stroke={`${role?.role_enabled ? "#969696" : "#E7E7E7"}`}
                      strokeWidth="1.5"
                    />
                  </svg>

                  {showDropdown && dropdownRoleId === role?.id && (
                    <div
                      className="action-dropdown"
                      ref={dropdownRef}

                      // style={{
                      //   bottom: index >= roleslist.length - 2 ? "83%" : "unset",
                      // }}
                    >
                      <div
                        className="d-flex"
                        onClick={(e) =>
                          handleNavigation(e, "viewroles", role?.id)
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.1667 7.99984C11.1667 9.4726 9.97276 10.6665 8.5 10.6665C7.02724 10.6665 5.83333 9.4726 5.83333 7.99984C5.83333 6.52708 7.02724 5.33317 8.5 5.33317C9.97276 5.33317 11.1667 6.52708 11.1667 7.99984ZM10.1667 7.99984C10.1667 8.92031 9.42048 9.6665 8.5 9.6665C7.57953 9.6665 6.83333 8.92031 6.83333 7.99984C6.83333 7.07936 7.57953 6.33317 8.5 6.33317C9.42048 6.33317 10.1667 7.07936 10.1667 7.99984Z"
                            fill="#969696"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.09459 8.52546C1.99516 8.18235 1.99516 7.81733 2.09459 7.47421C2.89938 4.69691 5.46189 2.6665 8.49863 2.6665C11.5354 2.6665 14.0979 4.69691 14.9027 7.47421C15.0021 7.81733 15.0021 8.18235 14.9027 8.52546C14.0979 11.3028 11.5354 13.3332 8.49863 13.3332C5.46189 13.3332 2.89938 11.3028 2.09459 8.52546ZM3.05508 7.75254C3.73933 5.39121 5.91887 3.6665 8.49863 3.6665C11.0784 3.6665 13.2579 5.39121 13.9422 7.75254C13.9889 7.91384 13.9889 8.08583 13.9422 8.24714C13.2579 10.6085 11.0784 12.3332 8.49863 12.3332C5.91887 12.3332 3.73933 10.6085 3.05508 8.24714C3.00833 8.08583 3.00833 7.91384 3.05508 7.75254Z"
                            fill="#969696"
                          />
                        </svg>
                        View
                      </div>
                      <div
                        className="d-flex"
                        onClick={(e) =>
                          handleNavigation(e, "editroles", role?.id)
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M3.67374 12.5402H3.74707L5.70041 12.3602C5.97374 12.3336 6.22707 12.2136 6.42041 12.0202L13.7937 4.64689C14.1404 4.30023 14.3337 3.84023 14.3337 3.35356C14.3337 2.86689 14.1404 2.40689 13.7937 2.06023L13.3204 1.58689C12.6271 0.893561 11.4204 0.893561 10.7271 1.58689L9.78707 2.52689L3.36041 8.95356C3.16707 9.14689 3.04707 9.40023 3.02707 9.67356L2.84707 11.6269C2.82707 11.8736 2.91374 12.1136 3.08707 12.2936C3.24707 12.4536 3.45374 12.5402 3.67374 12.5402ZM12.0271 2.04689C12.2404 2.04689 12.4537 2.12689 12.6137 2.29356L13.0871 2.76689C13.165 2.84346 13.2268 2.93477 13.269 3.03549C13.3113 3.13622 13.333 3.24434 13.333 3.35356C13.333 3.46278 13.3113 3.5709 13.269 3.67163C13.2268 3.77235 13.165 3.86366 13.0871 3.94023L12.5004 4.52689L10.8537 2.88023L11.4404 2.29356C11.6004 2.13356 11.8137 2.04689 12.0271 2.04689ZM4.02041 9.76689C4.02041 9.72689 4.04041 9.69356 4.06707 9.66689L10.1404 3.58689L11.7871 5.23356L5.71374 11.3069C5.71374 11.3069 5.64707 11.3536 5.61374 11.3536L3.86041 11.5136L4.02041 9.76023V9.76689ZM15.6671 14.6669C15.6671 14.9402 15.4404 15.1669 15.1671 15.1669H1.83374C1.56041 15.1669 1.33374 14.9402 1.33374 14.6669C1.33374 14.3936 1.56041 14.1669 1.83374 14.1669H15.1671C15.4404 14.1669 15.6671 14.3936 15.6671 14.6669Z"
                            fill="black"
                          />
                        </svg>
                        Edit
                      </div>
                      <div
                        className="d-flex"
                        onClick={(e) => handleDelete(e, role?.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="14"
                          viewBox="0 0 13 14"
                          fill="none"
                        >
                          <path
                            d="M7.07572 0.0908203H5.9242C5.4661 0.0908203 5.02676 0.2728 4.70284 0.596727C4.37891 0.920653 4.19693 1.35999 4.19693 1.81809V2.39385H0.742384C0.589683 2.39385 0.443237 2.45451 0.335261 2.56249C0.227286 2.67046 0.166626 2.81691 0.166626 2.96961C0.166626 3.12231 0.227286 3.26875 0.335261 3.37673C0.443237 3.48471 0.589683 3.54537 0.742384 3.54537H1.31814V11.606C1.31814 12.2168 1.56078 12.8026 1.99268 13.2345C2.42458 13.6664 3.01037 13.909 3.62117 13.909H9.37875C9.98955 13.909 10.5753 13.6664 11.0072 13.2345C11.4391 12.8026 11.6818 12.2168 11.6818 11.606V3.54537H12.2575C12.4102 3.54537 12.5567 3.48471 12.6647 3.37673C12.7726 3.26875 12.8333 3.12231 12.8333 2.96961C12.8333 2.81691 12.7726 2.67046 12.6647 2.56249C12.5567 2.45451 12.4102 2.39385 12.2575 2.39385H8.80299V1.81809C8.80299 1.35999 8.62101 0.920653 8.29708 0.596727C7.97316 0.2728 7.53382 0.0908203 7.07572 0.0908203ZM5.34844 1.81809C5.34844 1.66539 5.4091 1.51895 5.51708 1.41097C5.62506 1.303 5.7715 1.24234 5.9242 1.24234H7.07572C7.22842 1.24234 7.37486 1.303 7.48284 1.41097C7.59081 1.51895 7.65147 1.66539 7.65147 1.81809V2.39385H5.34844V1.81809ZM10.5303 11.606C10.5303 11.9114 10.4089 12.2043 10.193 12.4202C9.97704 12.6362 9.68415 12.7575 9.37875 12.7575H3.62117C3.31577 12.7575 3.02288 12.6362 2.80693 12.4202C2.59098 12.2043 2.46966 11.9114 2.46966 11.606V3.54537H10.5303V11.606Z"
                            fill="#969696"
                          />
                          <path
                            d="M6.5002 5.27246C6.3475 5.27246 6.20105 5.33312 6.09307 5.4411C5.9851 5.54907 5.92444 5.69552 5.92444 5.84822V10.4543C5.92444 10.607 5.9851 10.7534 6.09307 10.8614C6.20105 10.9694 6.3475 11.03 6.5002 11.03C6.6529 11.03 6.79934 10.9694 6.90732 10.8614C7.01529 10.7534 7.07595 10.607 7.07595 10.4543V5.84822C7.07595 5.69552 7.01529 5.54907 6.90732 5.4411C6.79934 5.33312 6.6529 5.27246 6.5002 5.27246Z"
                            fill="#969696"
                          />
                          <path
                            d="M8.22729 10.4543C8.22729 10.607 8.28796 10.7534 8.39593 10.8614C8.50391 10.9694 8.65035 11.03 8.80305 11.03C8.95575 11.03 9.1022 10.9694 9.21017 10.8614C9.31815 10.7534 9.37881 10.607 9.37881 10.4543V5.84822C9.37881 5.69552 9.31815 5.54907 9.21017 5.4411C9.1022 5.33312 8.95575 5.27246 8.80305 5.27246C8.65035 5.27246 8.50391 5.33312 8.39593 5.4411C8.28796 5.54907 8.22729 5.69552 8.22729 5.84822V10.4543Z"
                            fill="#969696"
                          />
                          <path
                            d="M4.19697 5.27246C4.04427 5.27246 3.89783 5.33312 3.78985 5.4411C3.68188 5.54907 3.62122 5.69552 3.62122 5.84822V10.4543C3.62122 10.607 3.68188 10.7534 3.78985 10.8614C3.89783 10.9694 4.04427 11.03 4.19697 11.03C4.34967 11.03 4.49612 10.9694 4.6041 10.8614C4.71207 10.7534 4.77273 10.607 4.77273 10.4543V5.84822C4.77273 5.69552 4.71207 5.54907 4.6041 5.4411C4.49612 5.33312 4.34967 5.27246 4.19697 5.27246Z"
                            fill="#969696"
                          />
                        </svg>
                        Delete
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {deleteModalShow && (
        <DeletePopup
          modalShow={deleteModalShow}
          handleModal={deleteConfirmation}
        />
      )}
    </>
  );
};

export default RolesListTable;
