import { configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "../features/date/dashboardSlice";
import addManagerSlice from "../features/addManager/addManagerSlice";
import toastslice from "../features/Toaster/toastslice";

const store = configureStore({
  reducer: {
    filterData: dashboardSlice,
    addManager: addManagerSlice,
    toast: toastslice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
