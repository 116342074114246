import React, { useEffect, useRef, useState } from "react";
import "../../styles/linechart.scss";
import "../../styles/livelist.scss";
import WorkingEmployeSkeleton from "../Skeletons/WorkingEmployeSkeleton";
import { commonGetService, handleFilterApi } from "../../utils/properties";
import { UnTracked } from "./ProductiveDetails";
import { Image } from "react-bootstrap";
import { useAppSelector,useAppDispatch } from "../../app/hooks";
import { showToast } from "../../features/Toaster/toastslice";

const LiveEmPloyeeList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [liveEmployeeData, setLiveEmployeeData] = useState<UnTracked[]>([]);

  const filterData = useAppSelector((state) => state.filterData);
  const dispatch = useAppDispatch();
  const { startDate, endDate, empDeviceId, filterType, organization, department } = filterData; // Destructuring the filterData state value
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          getLiveEmployeeData();

          observer.disconnect();
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [filterData]);

  const getLiveEmployeeData = () => {
    setIsLoading(true);
    commonGetService(`/api/1.0/dashboard/users/active?${handleFilterApi(startDate, endDate, filterType, organization, empDeviceId, department, false)}`).
      then(res => {
        setIsLoading(false);
        if (res.status === 200) {
          setLiveEmployeeData(res?.data);
        }
        else {
          dispatch(
            showToast({
              message: "Something went wrong in API.",
              status: "retry",
              visible: true,
            })
          );
          setLiveEmployeeData([]);
        }
      })
  }

  return (
    <div className="livelist " ref={targetRef}>
      <div className="flex-between ">
        <h4 className="heading-mb">Currently Working Employees</h4>
        <div className="flex-end">
          <span className="people-remaining">Hours Tracked Report</span>
        </div>
      </div>
      {isLoading ? (
        <div className="skeleton-list-wrapper">
          <WorkingEmployeSkeleton count={8} />
        </div>
      ) : (
        <div className="employee-grid">
          {liveEmployeeData.length ? liveEmployeeData?.filter((res, ind) => ind < 8)?.map((employee, index) => (
            <div key={index} className="flex-start">
              <div
                className={
                  employee?.image
                    ? "employee-live-pic-container"
                    : "employee-live-pic-container fill-color-avatar"
                }
              >
                {employee?.image ? (
                  <img src={employee?.image} alt={employee?.employee_name} />
                ) : (
                  <span>{employee?.employee_name.charAt(0)}</span>
                )}
              </div>
              <div className="employee-info">
                <p className="emp-name" title={employee?.employee_name}>
                  {employee?.employee_name}
                </p>
                <p className="designation">{employee?.designation}</p>
              </div>
            </div>
          )) : (<div className="d-flex flex-row  w-100 no-data-row">
            <div className="no-data-image-wrapper no-user-image">
              <Image src="/images/dashboard/user_icon.png" alt="No Data Found" />
            </div>
            <p>No users to show</p>
          </div>)}
        </div>
      )}
      {liveEmployeeData?.length > 8 && !isLoading &&
        <div className="flex-end">
          <span className="people-remaining">
            {`+${liveEmployeeData?.length - 8} Peoples`}
          </span>
        </div>
      }
    </div>
  );
};

export default LiveEmPloyeeList;
