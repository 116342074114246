import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const WorkedHoursSkeleton = ({ count }: { count: number }) => {
  const arr = Array(count).fill(0);
  return (
    <React.Fragment>
      {arr.map((_, index) => (
        <SkeletonTheme
          key={`${index}skl`}
          // baseColor="#F6AEAB"
          // highlightColor="#AE2C27"
        >
          <div className="skeleton-box-hours">
            <div className="pic-skeleton">
              <div className="circle-skeleton">
                <Skeleton circle={true} width={32} height={32} />
              </div>
            </div>

            <div className="detail-skeleton-hours w-29">
              <Skeleton />
            </div>
            <div className="detail-skeleton-hours w-47">
              <Skeleton />
            </div>
            <div className="detail-skeleton-hours w-13">
              <Skeleton />
            </div>
          </div>
        </SkeletonTheme>
      ))}
    </React.Fragment>
  );
};

export default WorkedHoursSkeleton;
