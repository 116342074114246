import React, { useState, useEffect, useRef } from 'react';
import NavbarLayout from '../components/NavbarLayout';
import '../styles/Managers/managerList.scss'
import { Button, Form, Image, Spinner } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { setEditUserDetails, setIsEdit, setModalShow } from '../features/addManager/addManagerSlice';
import DeletePopup from '../components/AddManager/DeletePopup';
import { commonGetService, commonPostservice, commonDeleteService, commonPutService,getCookie } from '../utils/properties';
import { showToast } from '../features/Toaster/toastslice';
import { useNavigate } from 'react-router';

export interface ManagerData {
    // id: string,
    // image: string,
    // email: string,
    // department: string,
    // designation: string,
    // role: string,
    // address: string,
    // employee_id: number,
    // employee_code: string,
    // employee_name: string,
    // reason: string,
    // device_id: string,
    // office_id: number,
    // office_name: string,
    // office_time: string,
    // map_link: string,
    // created_at: string,
    // isDropShow?: boolean,
    // xeroxRole?: string,
    // isEdit?: boolean

    status: string,
    id: string,
    email: string,
    password: string,
    role_id: string,
    sr_no: string,
    created_date: string,
    updated_date?: string,
    role: string,
    employee_code?: string
    // filterId?: number | undefined
}

export interface FeatureAccess {
    full_access: boolean,
    create_access: boolean,
    view_access: boolean,
    edit_access: boolean,
    delete_access: boolean
}

export interface RoleFeatureCategoryMapping {
    id?: string,
    feature_category: {
        id?: string,
        category_name: string
    },
    feature_access: FeatureAccess
}

export interface RolePermission {
    id?: string,
    role_permission_category: string,
    role_permission_enabled: boolean,
    role_feature_category_mapping: RoleFeatureCategoryMapping[]
}
export interface RolesData {
    id: string,
    role: string,
    description: string,
    created_date: string,
    sr_no: string,
    modified_date?: string,
    role_enabled: boolean,
    role_permissions: RolePermission[]
}

const ManagerList = () => {
    const [managerList, setManagerList] = useState<ManagerData[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSave, setIsSave] = useState<boolean>(false);
    // const [rolesList, setRolesList] = useState<RolesData[]>([]);
    // const [selectedManager, setSelectedManager] = useState<ManagerData | null>(null);
    // const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);



    const targetRef = useRef<HTMLDivElement | null>(null);
    const addManager = useAppSelector((state) => state.addManager)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        // checkCookie();
        getManagerList();
        // window.addEventListener("click", handleClickOutside);

        // return () => {
        //     window.removeEventListener("click", handleClickOutside);
        // };
    }, [addManager?.isManagerCreate])

    // const checkCookie = () => {
    //     if (getCookie() === undefined) {
    //       navigate("/");
    //     }
    //   };

    // Get All the Manager Details in the organization
    const getManagerList = () => {
        setIsLoading(true);
        commonGetService(`/api/manager/list/all`).then(res => {
            setIsLoading(false);
            // getRolesList();
            if (res.status === 200) {
                // res?.data?.map((val: ManagerData) => {
                //     val.isDropShow = false;
                //     val.xeroxRole = val?.role;
                //     val.isEdit = false;
                //     val.filterId = Math.floor(Math.random() * 90000) + 10000;
                // })
                setManagerList(res?.data);
            }
            else {
                dispatch(
                    showToast({
                        message: "Something went wrong in API",
                        status: "retry",
                        visible: true,
                    })
                );
            }
        })
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, roleId: string) => {
        const params = {
            id: roleId,
            status: e.target.checked ? "active" : "inactive"
        }
        setIsSave(true)
        commonPutService(`/api/manager/update`, params).then(res => {
            setIsSave(false);
            if (res.status === 200) {
                getManagerList();
            }
            else {
                dispatch(
                    showToast({
                        message: "Something went wrong in API",
                        status: "retry",
                        visible: true,
                    })
                );
            }
        })
    }

    const handleEdit = (data: ManagerData) => {
        dispatch(setEditUserDetails(data));
        dispatch(setIsEdit(true));
        dispatch(setModalShow(true))
    }

    // Get The available roles in the organization
    // const getRolesList = () => {
    //     commonGetService(`/api/1.0/role/list/all`).then(res => {
    //         if (res.status === 200) {
    //             setRolesList(res?.data);
    //         }
    //     })
    // }

    // Funtion for to close the role dropdown when we click on the outside of the dropdown
    // const handleClickOutside = (e: MouseEvent) => {
    //     if (
    //         targetRef.current &&
    //         e.target instanceof Node &&
    //         !targetRef.current.contains(e.target)
    //     ) {
    //         setManagerList(prevManagerList => {
    //             return prevManagerList?.map((res: ManagerData) => {
    //                 if (res.isDropShow) {
    //                     res.isDropShow = false
    //                 }
    //                 return res;
    //             })
    //         })
    //     }
    // };

    //Funtion for to open and close the roles dropdown that is clicked by User
    // const handleRoleDropdown = (idn: number | undefined, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    //     let updatedList = managerList?.map((res: ManagerData) => {
    //         if (res.employee_id === idn) {
    //             res.isDropShow = !res.isDropShow
    //         }
    //         if (res.isDropShow && res.employee_id !== idn) {
    //             res.isDropShow = false
    //         }
    //         return res;
    //     })
    //     setManagerList(updatedList)
    //     e.stopPropagation();
    // }

    // Function to handle the role change
    // const handleRoleSelect = (role: string, eId: number) => {
    //     const newManagerList = managerList?.map((res: ManagerData) => {
    //         if (res.employee_id === eId) {
    //             if (res.xeroxRole !== role) {
    //                 res.isEdit = true;
    //             }
    //             else {
    //                 res.isEdit = false
    //             }
    //             res.role = role;
    //             res.isDropShow = false;
    //         }
    //         return res;
    //     })
    //     setManagerList(newManagerList);
    // }

    // Function for to save the role changes in the manager
    // const handleSave = () => {
    //     let params: any[] = [];
    //     managerList?.map((res: ManagerData) => {
    //         if (res.isEdit) {
    //             params.push({
    //                 "device_id": res?.device_id,
    //                 "role_name": res?.role
    //             })
    //         }
    //     })

    //     setIsSave(true);
    //     commonPostservice(`/user/managers`, params).then(res => {
    //         setIsSave(false);
    //         if (res.message === "Success") {
    //             getManagerList();
    //         }
    //     })
    // }

    // const handleDelete = (val: boolean, action: string) => {
    //     if (action === 'Yes') {
    //         commonDeleteService(`/user/manager/delete?deviceId=${selectedManager?.device_id}`).then(res => {
    //             if (res.status === 200) {
    //                 getManagerList();
    //                 setDeleteModalShow(false);
    //                 setSelectedManager(null);
    //             }
    //         })
    //     }
    //     else {
    //         setDeleteModalShow(false);
    //         setSelectedManager(null);
    //     }
    // }

    return (
        <NavbarLayout>
            <div className='mgr-container'>
                <div className='list-mgr-contaniner d-flex flex-column'>
                    <div className='add-mgr d-flex flex-row justify-content-between w-100'>
                        <div className='d-flex flex-column'>
                            <h3>List of Managers</h3>
                            <div className='search-bar mt-4'>
                                <Form.Control type='text' placeholder='Search using Name, ID and Department' value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                                <Image
                                    src="/images/dashboard/grey_search.svg"
                                    alt="Search_Icon"
                                    className="position-absolute"
                                />
                            </div>
                        </div>
                        <div className='btn-cont'>
                            <Button onClick={() => dispatch(setModalShow(true))}>+ Add Manager</Button>
                        </div>
                    </div>

                    <div className='mgr-list w-100 common-scroll mx-auto'>
                        <table className='w-100'>
                            <thead>
                                <tr>
                                    {/* <th>Emp ID</th>
                                    <th>Employee</th>
                                    <th>Designation</th>
                                    <th>Department</th>
                                    <th>Floor</th>
                                    <th>Created Date</th> */}
                                    <th>Email-Id</th>
                                    <th>Role</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className='common-scroll'>
                                {isLoading ?
                                    <tr>
                                        <td className='spin-col' colSpan={8}>
                                            <div className="d-flex justify-content-center align-items-center h-100 w-100 crt-loader">
                                                <Spinner animation="border" />
                                            </div>
                                        </td>
                                    </tr> : managerList?.length ? managerList?.filter((val) => {
                                        if (searchText.trim() === "") {
                                            return val;
                                        }
                                        else if (val?.email.trim().toLowerCase().includes(searchText.trim().toLowerCase()) ||
                                            val?.role.trim().toLowerCase().includes(searchText.trim().toLowerCase())) {
                                            return val;
                                        }
                                    })?.map((res, ind) => (
                                        <tr key={ind}>
                                            {/* <td>{res?.employee_code}</td>
                                            <td>
                                                <div className='d-flex flex-row align-items-center emp-details'>
                                                    {res?.image ?
                                                        <div>
                                                            <Image src={res?.image} alt='Manager Pic' roundedCircle />
                                                        </div> :
                                                        <div className="img-avatar d-flex justify-content-center align-items-center">
                                                            <p>{res?.employee_name?.charAt(0)}</p>
                                                        </div>
                                                    }
                                                    <div className='d-flex flex-column ms-2 common-overflow'>
                                                        <p className='mgr-name'>{res?.employee_name}</p>
                                                        <p>{res?.email}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td><div>{res?.designation}</div></td>
                                            <td><div>{res?.department}</div></td>
                                            <td><div>{res?.office_name}</div></td>
                                            <td>{res?.created_at}</td>
                                            <td>
                                                <div className='d-flex justify-content-center' ref={targetRef}>
                                                    <div className='position-relative'>
                                                        <div className={`role-drop-head d-flex flex-row justify-content-between align-items-center ${res?.isDropShow ? 'drop-show' : ''}`} onClick={(e) => {
                                                            handleRoleDropdown(res?.employee_id, e);
                                                        }}>
                                                            <p className='mb-0 text-capitalize'>{res?.role}</p>
                                                            <div>
                                                                <Image src={`/images/roles/${!res?.isDropShow ? "role_down_arrow" : "role_up_arrow"}.svg`}
                                                                    alt={`${res?.isDropShow ? "Down Arrow" : "Up Arrow"}`} />
                                                            </div>
                                                        </div>
                                                        <div className={`position-absolute z-1 ${res?.isDropShow ? "d-block" : "d-none"}`}>
                                                            <div className='d-flex flex-column role-drop-body'>
                                                                {rolesList?.map((role, ind) => (
                                                                    <div className='cus-role-drop text-capitalize' key={ind}
                                                                        onClick={() => handleRoleSelect(role?.role, res?.employee_id)}
                                                                    >
                                                                        {role?.role}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td onClick={() => {
                                                setDeleteModalShow(true);
                                                setSelectedManager(res);
                                            }}>
                                                <Image src='/images/roles/delete_icon.svg' alt='Delete' />
                                            </td> */}


                                            <td>
                                                <div>{res?.email}</div>
                                            </td>
                                            <td>
                                                <div className='text-capitalize'>{res?.role}</div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-center align-items-center edit-grp'>
                                                    <div onClick={() => handleEdit(res)}>
                                                        <Image src='/images/roles/manager_edit_icon.svg' alt='edit' />
                                                    </div>
                                                    <Form.Check
                                                        type="switch"
                                                        checked={res?.status === "active"}
                                                        id={res?.email}
                                                        onChange={(e) => handleOnChange(e, res?.id)}
                                                        disabled={isSave}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    )) :
                                        <tr>
                                            <td className='no-data' colSpan={8}>
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <Image
                                                        src="/images/dashboard/user_icon.png"
                                                        alt="No Data Found"
                                                    />
                                                    <p className="m-0">No users to show</p>
                                                </div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* {managerList?.filter(res => res.isEdit === true)?.length > 0 &&
                        <div className='save-btn ms-auto'>
                            <Button disabled={isSave} onClick={() => handleSave()}>Save Changes</Button>
                        </div>
                    } */}
                </div>
            </div>

            {/* Delete Modal Component */}
            {/* {deleteModalShow &&
                <DeletePopup modalShow={deleteModalShow} handleModal={handleDelete} />
            } */}

        </NavbarLayout>
    );
};

export default ManagerList;