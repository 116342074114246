// WorkingEmployeSkeleton.tsx
import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const WorkingEmployeSkeleton = ({ count }: { count: number }) => {
  const arr = Array(count).fill(0);
  return (
    <React.Fragment>
      {arr.map((_, index) => (
        <SkeletonTheme
          key={`${index}skl`}
          // baseColor="#F6AEAB"
          // highlightColor="#AE2C27"
        >
          <div className="skeleton-container">
            <div className="skeleton-box">
              <div className="pic-skeleton">
                <div className="circle-skeleton">
                  <Skeleton circle={true} width={32} height={32} />
                </div>
              </div>
              <div className="detail-skeleton">
                <Skeleton count={2} />
              </div>
            </div>
          </div>
        </SkeletonTheme>
      ))}
    </React.Fragment>
  );
};

export default WorkingEmployeSkeleton;
