import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ToastState {
  message: string;
  visible: boolean;
  status: string;
}

const initialState: ToastState = {
  message: "",
  visible: false,
  status:"",
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showToast(state, action: PayloadAction<ToastState>) {
      state.message = action.payload.message;
      state.visible =true;
      state.status = action.payload.status;
    },
    hideToast(state) {
      state.visible = false;
    },
  },
});

export const { showToast, hideToast } = toastSlice.actions;
export default toastSlice.reducer;
