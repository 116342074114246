import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Image, ProgressBar } from "react-bootstrap";
import WorkedHoursSkeleton from "../Skeletons/WorkedHoursSkeleton";
import { commonGetService, handleFilterApi } from "../../utils/properties";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { showToast } from "../../features/Toaster/toastslice";

interface UserInfo {
  image: string,
  email: string,
  department: string,
  designation: string,
  employee_id: number,
  employee_code: string,
  employee_name: string,
  reason: string,
  device_id: string
}
interface WorkedHours {
  deviceId: string,
  total_time: string,
  total_time_percentage: number,
  idle_time: null | number,
  idle_time_percentage: number,
  user_info: UserInfo
}

const WorkedHours = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [mostWorkedHours, setMostWorkedHours] = useState<WorkedHours[]>([]);
  const [leastWorkedHours, setLeastWorkedHours] = useState<WorkedHours[]>([]);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);


  const filterData = useAppSelector((state) => state.filterData)
  const dispatch = useAppDispatch();
  const { startDate, endDate, empDeviceId, filterType, organization, department } = filterData; // Destructuring the filterData state value

  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.25,
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInitialLoad(false);
          geMostWorkedHoursDetails();
          getLeastWorkedHoursDetails();

          observer.disconnect();
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [filterData]);

  // useEffect(() => {
  //   if (!initialLoad) {
  //     geMostWorkedHoursDetails();
  //     getLeastWorkedHoursDetails();
  //   }
  // }, [filterData])

  const geMostWorkedHoursDetails = () => {
    setIsLoading(true);
    commonGetService(`/api/1.0/dashboard/work/most/hours?${handleFilterApi(startDate, endDate, filterType, organization, empDeviceId, department, false)}`).
      then(res => {
        setIsLoading(false);
        if (res.status === 200) {
          setMostWorkedHours(res?.data);
        }
        else {
          dispatch(
            showToast({
              message: "Something went wrong in API.",
              status: "retry",
              visible: true,
            })
          );
        }
      })
  }

  const getLeastWorkedHoursDetails = () => {
    setIsLoading(true);
    commonGetService(`/api/1.0/dashboard/work/least/hours?${handleFilterApi(startDate, endDate, filterType, organization, empDeviceId, department, false)}`).
      then(res => {
        setIsLoading(false);
        if (res.status === 200) {
          setLeastWorkedHours(res?.data);
        }
        else {
          dispatch(
            showToast({
              message: "Something went wrong in API.",
              status: "retry",
              visible: true,
            })
          );
        }
      })
  }

  return (
    <div className="wkd-container" ref={targetRef}>
      <Row className="m-0">
        <Col className="wkd-most d-flex flex-column" lg>
          <div className="d-flex justify-content-between common-card-title">
            <h5>Worked most hours</h5>
            <p className="cursor-pointer">Hours tracked report</p>
          </div>
          {isLoading ? (
            <WorkedHoursSkeleton count={5} />
          ) : (
            <>
              {mostWorkedHours.length ? mostWorkedHours?.map((res, ind) => (
                <div className="d-flex flex-row align-items-center wkd-emp-dts w-100" key={ind}>
                  <div className="img-ctr">
                    {res?.user_info?.image ?
                      <Image
                        src={res?.user_info?.image}
                        alt={res?.user_info?.employee_name}
                      /> :
                      <div className="img-avatar d-flex justify-content-center align-items-center">
                        <p>{res?.user_info?.employee_name?.charAt(0)}</p>
                      </div>
                    }
                  </div>
                  <div className="name-ctr">
                    <p>{res?.user_info?.employee_name}</p>
                  </div>
                  <div className="pg-bar-ctr">
                    <ProgressBar now={res?.total_time_percentage} variant={ind === 0 ? "green-one" : ind === 1 ? "green-two" : ind === 2 ? "green-three" : ind === 3 ? "green-four" : "green-five"} />
                  </div>
                  <div className="time-ctr">
                    <p>{res?.total_time}</p>
                  </div>
                </div>
              )) : (<div className="d-flex flex-row  w-100 no-data-row">
                <div className="no-data-image-wrapper no-user-image">
                  <Image src="/images/dashboard/user_icon.png" alt="No Data Found" />
                </div>
                <p>No users to show</p>
              </div>)}
            </>
          )}
        </Col>
        <Col className="wkd-most d-flex flex-column" lg>
          <div className="d-flex justify-content-between common-card-title">
            <h5>Worked least hours</h5>
            <p className="cursor-pointer">Hours tracked report</p>
          </div>
          {isLoading ? (
            <WorkedHoursSkeleton count={5} />
          ) : (
            <>
              {leastWorkedHours.length ? leastWorkedHours?.map((res, ind) => (
                <div className="d-flex flex-row align-items-center wkd-emp-dts w-100" key={ind}>
                  <div className="img-ctr">
                    {res?.user_info?.image ?
                      <Image
                        src={res?.user_info?.image}
                        alt={res?.user_info?.employee_name}
                      /> :
                      <div className="img-avatar d-flex justify-content-center align-items-center">
                        <p>{res?.user_info?.employee_name?.charAt(0)}</p>
                      </div>
                    }
                  </div>
                  <div className="name-ctr">
                    <p>{res?.user_info?.employee_name}</p>
                  </div>
                  <div className="pg-bar-ctr">
                    <ProgressBar now={res?.total_time_percentage} variant={ind === 0 ? "red-one" : ind === 1 ? "red-two" : ind === 2 ? "red-three" : ind === 3 ? "red-four" : "red-five"} />
                  </div>
                  <div className="time-ctr">
                    <p>{res?.total_time}</p>
                  </div>
                </div>
              )) : (<div className="d-flex flex-row  w-100 no-data-row">
                <div className="no-data-image-wrapper no-user-image">
                  <Image src="/images/dashboard/user_icon.png" alt="No Data Found" />
                </div>
                <p>No users to show</p>
              </div>)}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default WorkedHours;
