import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const AppsSkeleton = ({ count }: { count: number }) => {
  const arr = Array(count).fill(0);

  return (
    <React.Fragment>
      {arr.map((_, index) => (
        <SkeletonTheme
          key={`${index}skl`}
          // baseColor="#F6AEAB"
          // highlightColor="#AE2C27"
        >
          <div className="skeleton-apps-hours">
            <div className="pic-skeleton">
              <div className="circle-skeleton">
                <Skeleton circle={true} width={32} height={32} />
              </div>
            </div>
            <div className="skeleton-right">
              <div className="app-hours-info">
                <div className="w-40">
                  <Skeleton />
                </div>
                <div className="w-24">
                  <Skeleton />
                </div>
              </div>
              <div className="detail-skeleton-hours w-13">
                <Skeleton />
              </div>
            </div>
          </div>
        </SkeletonTheme>
      ))}
    </React.Fragment>
  );
};

export default AppsSkeleton;
