import React, { useState, useEffect } from "react";
import { Container, Image, Form, Button, Spinner } from "react-bootstrap";
import "../styles/login.scss";
import { useNavigate } from "react-router";
import { commonPostservice, getCookie } from "../utils/properties";
import { useAppDispatch } from "../app/hooks";
import { showToast } from "../features/Toaster/toastslice";

const Login = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userPassword, setUserPassword] = useState<string>("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (getCookie() !== undefined) {
      navigate("/dashboard");
    }
  });

  const handlePasswordShow = () => {
    setShowPassword(!showPassword);
  };

  const handleValidate = () => {
    if (userName.trim() === "") {
      alert("Please enter the user name");
      return false;
    } else if (userPassword.trim() === "") {
      alert("Please enter the password");
      return false;
    }
    return true;
  };

  const handleLogin = () => {
    if (handleValidate()) {
      let params = {
        username: userName,
        password: userPassword,
      };
      setIsLoading(true);
      commonPostservice(`/api/auth/login`, params).then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          let d = new Date();
          d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
          let expireTime = d.toUTCString();
          document.cookie = `X-XSRF-TOKEN = ${res?.data?.token};expires=${expireTime};path=/`;
          navigate("dashboard");
        } else {
          dispatch(
            showToast({
              message: "Invalid Credentials",
              status: "retry",
              visible: true,
            })
          );
        }
      });
    }
  };

  return (
    <Container fluid className="login-container">
      <div className="body-container d-flex justify-content-center align-items-center">
        <div className="outer-circle">
          <div className="inner-circle d-flex justify-content-center align-items-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div>
                <Image
                  src="/images/login/timelyze_logo.svg"
                  className="logo"
                  alt="Timetracker Logo"
                />
              </div>
              <div className="user-inp">
                <Form.Control
                  type="text"
                  placeholder="Enter Your Username"
                  className="inp-pts"
                  value={userName}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleLogin();
                    }
                  }}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
              <div className="pass-inp position-relative">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Your Password"
                  className="inp-pts"
                  value={userPassword}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleLogin();
                    }
                  }}
                  onChange={(e) => setUserPassword(e.target.value)}
                />
                {!showPassword ? (
                  <Image
                    src="/images/login/password_hide.svg"
                    alt="Password Hide"
                    className="position-absolute cursor-pointer"
                    onClick={handlePasswordShow}
                  />
                ) : (
                  <Image
                    src="/images/login/password_show.svg"
                    alt="Password Show"
                    className="position-absolute cursor-pointer"
                    onClick={handlePasswordShow}
                  />
                )}
              </div>
              <div className="btn-ctr">
                <Button
                  variant="light"
                  onClick={() => handleLogin()}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner as="span" animation="border" size="sm" />
                  ) : (
                    "Login"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Login;
