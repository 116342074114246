import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { commonGetService, handleFilterApi } from "../../utils/properties";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router";
import { showToast } from "../../features/Toaster/toastslice";

interface ScreenShots {
  url: string;
  description: string;
  device_id: string;
  emp_name: string;
  emp_code: string;
}
const Screenshot = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [screenshots, setScreenshots] = useState<ScreenShots[]>([]);
  const filterData = useAppSelector((state) => state.filterData);
  const dispatch = useAppDispatch();

  const {
    startDate,
    endDate,
    empDeviceId,
    filterType,
    organization,
    department,
  } = filterData; // Destructuring the filterData state value
  const targetRef = useRef<HTMLDivElement>(null);

  const showScreenshot = process.env.REACT_APP_DISPLAY_SCREENSHOT;

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          getScreenShots();

          observer.disconnect();
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [filterData]);

  const getScreenShots = () => {
    setIsLoading(true);
    commonGetService(
      `/api/1.0/dashboard/screenshots/recent?${handleFilterApi(
        startDate,
        endDate,
        filterType,
        organization,
        empDeviceId,
        department,
        true
      )}`
    ).then((res) => {
      setIsLoading(false);
      if (res.status === 200) {
        setScreenshots(res?.data);
      } else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };
  const arr = Array(4).fill(0);

  return (
    <div className="sst-container d-flex flex-column" ref={targetRef}>
      <div className="d-flex flex-row justify-content-between common-card-title">
        <h5>Recent screenshots</h5>
        {screenshots.length > 0 && !isLoading && (
          <p
            className="cursor-pointer"
            onClick={() => navigate("/screenshots")}
          >
            View all
          </p>
        )}
      </div>
      <div className="sct-img-cont d-flex flex-row">
        {screenshots.length || isLoading ? (
          (isLoading ? arr : screenshots)?.map((item, index) => (
            <div className="sct-img d-flex flex-column" key={index}>
              <div>
                {isLoading ? (
                  <Skeleton count={1} className="sst-img" />
                ) : (
                  <Image
                    src={
                      showScreenshot === "TRUE"
                        ? item?.url
                        : "/images/screenshots/screenshots.jpg"
                    }
                    alt="Screenshot"
                    className="sst-img"
                  />
                )}
              </div>
              <div className="sct-details d-flex flex-column">
                <div>
                  <h6 className="mb-0">
                    {isLoading ? (
                      <Skeleton count={1} className="w-25" />
                    ) : (
                      item?.emp_name
                    )}
                  </h6>
                </div>
                <div className="d-flex flex-row justify-content-between emp-id w-100">
                  <p className={isLoading ? "skel-name" : ""}>
                    {isLoading ? (
                      <Skeleton
                        count={1}
                        className="w-75"
                        containerClassName="w-100"
                      />
                    ) : (
                      item?.emp_code
                    )}
                  </p>
                  <h6 className={isLoading ? "skel-name" : ""}>
                    {isLoading ? (
                      <Skeleton
                        count={1}
                        className="w-75"
                        containerClassName="w-100"
                      />
                    ) : (
                      item?.emp_name
                    )}
                  </h6>
                  <p className={isLoading ? "skel-name" : ""}>
                    {isLoading ? (
                      <Skeleton
                        count={1}
                        className="w-75"
                        containerClassName="w-100"
                      />
                    ) : (
                      item?.description
                    )}
                  </p>
                </div>
                {/* <div className="emp-name">
                  <p>Taken by: Ajay Deb (Manager)</p>
                </div> */}
              </div>
            </div>
          ))
        ) : (
          <div className="d-flex flex-row  w-100 no-data-row">
            <div className="no-data-image-wrapper">
              <Image src="/images/dashboard/folder_icon.svg" alt="Folder" />
            </div>
            <p>No Screenshots to show</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Screenshot;
