import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Image, Modal, Carousel } from "react-bootstrap";
import { commonGetService, handleFilterApi } from "../../utils/properties";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Skeleton from "react-loading-skeleton";
import { showToast } from "../../features/Toaster/toastslice";

interface ScreenShots {
  url: string;
  description: string;
  device_id: string;
  emp_name: string;
  emp_code: string;
}
const Screenshots = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [screenshots, setScreenshots] = useState<ScreenShots[]>([]);
  const filterData = useAppSelector((state) => state.filterData);
  const dispatch = useAppDispatch();
  const showScreenshot = process.env.REACT_APP_DISPLAY_SCREENSHOT;
  const {
    startDate,
    endDate,
    empDeviceId,
    filterType,
    organization,
    department,
  } = filterData; // Destructuring the filterData state value

  useEffect(() => {
    getScreenShots();
  }, [filterData]);

  const getScreenShots = () => {
    setIsLoading(true);
    commonGetService(
      `/api/1.0/dashboard/screenshots/recent?${handleFilterApi(
        startDate,
        endDate,
        filterType,
        organization,
        empDeviceId,
        department,
        false
      )}`
    ).then((res) => {
      setIsLoading(false);
      if (res.status === 200) {
        setScreenshots(res?.data);
      } else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  const handleModal = (data: string) => {
    setShowModal(!showModal);

    let index = screenshots?.findIndex((item) => item?.url === data);
    setActiveIndex(index);
  };
  const arr = Array(8).fill(0);

  return (
    <div className="sst-container d-flex flex-column screenshots-page-container">
      <div className="sct-img-cont d-flex flex-row cursor-pointer screenshot-cards-wrapper">
        {screenshots.length || isLoading ? (
          (isLoading ? arr : screenshots)?.map((item, index) => (
            <div
              className="sct-img d-flex flex-column screen-card"
              key={index}
              onClick={() => handleModal(item?.url)}
            >
              <div className="image-wrapper">
                {isLoading ? (
                  <Skeleton count={1} className="sst-img" />
                ) : (
                  <Image
                    src={
                      showScreenshot === "TRUE"
                        ? item?.url
                        : "/images/screenshots/screenshots.jpg"
                    }
                    // src="/images/screenshots/screenshots.jpg"
                    alt="Screenshot"
                    className="sst-img"
                  />
                )}
              </div>
              <div className="sct-details d-flex flex-column">
                <div>
                  <h6 className="mb-0">
                    {isLoading ? (
                      <Skeleton count={1} className="w-25" />
                    ) : (
                      item?.emp_name
                    )}
                  </h6>
                </div>
                <div className="d-flex flex-row justify-content-between emp-id w-100">
                  <p className={isLoading ? "skel-name" : ""}>
                    {isLoading ? (
                      <Skeleton
                        count={1}
                        className="w-75"
                        containerClassName="w-100"
                      />
                    ) : (
                      item?.emp_code
                    )}
                  </p>
                  <h6 className={isLoading ? "skel-name" : ""}>
                    {isLoading ? (
                      <Skeleton
                        count={1}
                        className="w-75"
                        containerClassName="w-100"
                      />
                    ) : (
                      item?.emp_name
                    )}
                  </h6>
                  <p className={isLoading ? "skel-name" : ""}>
                    {isLoading ? (
                      <Skeleton
                        count={1}
                        className="w-75"
                        containerClassName="w-100"
                      />
                    ) : (
                      item?.description
                    )}
                  </p>
                </div>
                {/* <div className="emp-name">
                  <p>Taken by: Ajay Deb (Manager)</p>
                </div> */}
              </div>
            </div>
          ))
        ) : (
          <div className="d-flex flex-row  w-100 no-data-row">
            <div className="no-data-image-wrapper">
              <Image src="/images/dashboard/folder_icon.svg" alt="Folder" />
            </div>
            <p>No Screenshots to show</p>
          </div>
        )}
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="lg"
        className="screenshots-preview-carousel"
      >
        <Modal.Body>
          <Carousel
            activeIndex={activeIndex}
            onSelect={(selectedIndex: number) => setActiveIndex(selectedIndex)}
            prevIcon={<span className="carousel-control-prev-icon" />}
            nextIcon={<span className="carousel-control-next-icon" />}
            interval={null}
            indicators={false}
            keyboard={true}
          >
            {screenshots?.map((item, index) => {
              return (
                <Carousel.Item key={index}>
                  <h5>{`${item?.emp_name} | ${item?.emp_code}`}</h5>

                  <img
                    className={`d-block w-100 ${
                      showScreenshot === "TRUE" ? "" : "restrict-image"
                    }`}
                    src={
                      showScreenshot === "TRUE"
                        ? item?.url
                        : "/images/screenshots/screenshots.jpg"
                    }
                    alt={`Screenshot ${index}`}
                  />

                  <h5>{`${item?.description} `}</h5>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Screenshots;
