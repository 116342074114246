import React, { useContext, useState, useEffect } from 'react';
import { Image, ProgressBar } from 'react-bootstrap';
import '../../styles/dashboard.scss'
import { useAppSelector } from '../../app/hooks';
import { activityContext, activityProps } from "../../pages/Dashboard";
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';


const ActivityTime = () => {
    const [isAllUser, setIsAllUser] = useState<boolean>(true);
    const activityProps: activityProps | null = useContext(activityContext);
    const filterData = useAppSelector((state) => state.filterData);
    const { startDate, endDate, empDeviceId,filterType } = filterData;
    const [isToday, setIsToday] = useState<boolean>(true);

    useEffect(() => {
        setIsAllUser(filterType === "organization" || filterType === "department");
        checkDate();
    }, [filterData])
    const checkDate = () => {
        let today = moment(new Date()).format("L");
        let fromDate = moment(startDate).format("L");
        let toDate = moment(endDate).format("L");
        setIsToday(today === fromDate && today === toDate);

    };

    return (
        <div className='activity-container'>
            <div className='d-flex flex-row justify-content-between w-100'>
                <div className='time-card d-flex flex-column'>
                    <div className='activity-logo d-flex align-items-center justify-content-center'>
                        <Image src='/images/dashboard/productive_icon.svg' alt='Total Productive Time' />
                    </div>
                    <h4>Total productive time</h4>
                    <p>{activityProps?.isLoading ? <Skeleton count={1} className='w-50' /> : activityProps?.activityDetails?.productive_time}</p>
                    <div className='activity-percent d-flex justify-content-between align-items-center'>
                        {activityProps?.isLoading ?
                            <>
                                <Skeleton className='pcg-box' count={1} />
                                <Skeleton className='cus-progress w-100' containerClassName="skel-progress" count={1} />
                            </>
                            :
                            <>
                                <div className='pcg-box'>{Number(activityProps?.activityDetails?.productive_percentage).toFixed(2)}%</div>
                                <ProgressBar className='cus-progress' now={Number(Number(activityProps?.activityDetails?.productive_percentage).toFixed(2))} variant="active-color" />
                            </>
                        }
                    </div>
                </div>
                <div className='time-card d-flex flex-column'>
                    <div className='activity-logo d-flex align-items-center justify-content-center'>
                        <Image src='/images/dashboard/unproductive_icon.svg' alt='Total Unproductive Time' />
                    </div>
                    <h4>Total unproductive time</h4>
                    {/* <p>1140 hrs 56 mins</p> */}
                    <p>{activityProps?.isLoading ? <Skeleton count={1} className='w-50' /> : activityProps?.activityDetails?.unproductive_time}</p>
                    <div className='activity-percent d-flex justify-content-between align-items-center'>
                        {activityProps?.isLoading ?
                            <>
                                <Skeleton className='pcg-box' count={1} />
                                <Skeleton className='cus-progress w-100' containerClassName="skel-progress" count={1} />
                            </> :
                            <>
                                <div className='pcg-box'>{Number(activityProps?.activityDetails?.unproductive_percentage).toFixed(2)}%</div>
                                <ProgressBar className='cus-progress' now={Number(Number(activityProps?.activityDetails?.unproductive_percentage).toFixed(2))} variant="active-color" />
                            </>
                        }
                    </div>
                </div>
                <div className='time-card d-flex flex-column'>
                    <div className='activity-logo d-flex align-items-center justify-content-center'>
                        <Image src={`/images/dashboard/${isAllUser ? isToday ? "idle_icon" : "neutral_icon" : "neutral_icon"}.svg`} alt='Total Neutral Time' />
                    </div>
                    <h4>{isAllUser ? isToday ? "Total idle time" : "Total neutral time" : "Total neutral time"}</h4>
                    {/* <p>232 hrs 43 mins</p> */}
                    <p>{activityProps?.isLoading ? <Skeleton count={1} className='w-50' /> : isAllUser ? isToday ? activityProps?.activityDetails?.idle_time : activityProps?.activityDetails?.neutral_time : activityProps?.activityDetails?.neutral_time}</p>
                    <div className='activity-percent d-flex justify-content-between align-items-center'>
                        {activityProps?.isLoading ?
                            <>
                                <Skeleton className='pcg-box' count={1} />
                                <Skeleton className='cus-progress w-100' containerClassName="skel-progress" count={1} />
                            </> :
                            <>
                                <div className='pcg-box'>{isAllUser ? isToday ? Number(activityProps?.activityDetails?.idle_time_percentage).toFixed(2) : Number(activityProps?.activityDetails?.neutral_percentage).toFixed(2) : Number(activityProps?.activityDetails?.neutral_percentage).toFixed(2)}%</div>
                                <ProgressBar className='cus-progress' now={isAllUser ? isToday ? Number(Number(activityProps?.activityDetails?.idle_time_percentage).toFixed(2)) : Number(Number(activityProps?.activityDetails?.neutral_percentage).toFixed(2)) : Number(Number(activityProps?.activityDetails?.neutral_percentage).toFixed(2))} variant="active-color" />
                            </>
                        }
                    </div>
                </div>
                <div className='time-card d-flex flex-column'>
                    <div className='activity-logo d-flex align-items-center justify-content-center'>
                        <Image src={`/images/dashboard/${isAllUser ? isToday ? "active_user" : "idle_icon" : "idle_icon"}.svg`} alt={isAllUser ? "Total active users" : "Total idle time"} />
                    </div>
                    <h4>{isAllUser ? isToday ? "Total active users" : "Total idle time" : "Total idle time"}</h4>
                    <p>{activityProps?.isLoading ? <Skeleton count={1} className='w-50' /> : isAllUser ? isToday ? activityProps?.activityDetails?.total_active_user : activityProps?.activityDetails?.idle_time : activityProps?.activityDetails?.idle_time}</p>
                    <div className='activity-percent d-flex justify-content-between align-items-center'>
                        {activityProps?.isLoading ?
                            <>
                                <Skeleton className='pcg-box' count={1} />
                                <Skeleton className='cus-progress w-100' containerClassName="skel-progress" count={1} />
                            </> :
                            <>
                                <div className='pcg-box danger'>{isAllUser ? isToday ? Number(activityProps?.activityDetails?.total_active_user_percentage).toFixed(2) : Number(activityProps?.activityDetails?.idle_time_percentage).toFixed(2) : Number(activityProps?.activityDetails?.idle_time_percentage).toFixed(2)}%</div>
                                <ProgressBar className='cus-progress' now={isAllUser ? isToday ? Number(Number(activityProps?.activityDetails?.total_active_user_percentage).toFixed(2)) : Number(Number(activityProps?.activityDetails?.idle_time_percentage).toFixed(2)) : Number(Number(activityProps?.activityDetails?.idle_time_percentage).toFixed(2))} variant="in-active-color" />
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivityTime;