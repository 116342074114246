import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import { Locale } from "@hassanmojab/react-modern-calendar-datepicker";

import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";

interface DayValue {
  year: number;
  month: number;
  day: number;
}

interface DayRange {
  from: DayValue | null;
  to: DayValue | null;
}
type DateRange = [Date | null, Date | null];

interface RangePickerProps {
  value: DayRange;
  onSelect: (dateRange: DateRange) => void;
}
type CalendarDigit = number | string;

const RangePicker = ({ value, onSelect }: RangePickerProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedDayRange, setSelectedDayRange] = useState<DayRange>({
    from: null,
    to: null,
  });
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [sameDaySelected, setSameDaySelected] = useState<boolean>(false);

  const rangeRef = useRef<HTMLDivElement | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const formatRange1: (range: DayRange) => [string] = (range) => {
    const { from, to } = range;
    const formattedFrom = from ? formatDate(from) : "";
    const formattedTo = to ? formatDate(to) : "";
    return [`${formattedFrom} , ${formattedTo}`];
  };

  const handleDayRangeChange = (newDayRange: DayRange) => {
    if (
      newDayRange?.from !== null &&
      newDayRange?.to !== null &&
      JSON.stringify(newDayRange?.from) === JSON.stringify(newDayRange?.to)
    ) {
      setSameDaySelected(true);
    } else {
      setSameDaySelected(false);
    }
    setSelectedDayRange(newDayRange);
  };

  const dateSelected = (newDayRange: DayRange) => {
    let newdates = formatRange1(newDayRange);
    let ranges = newdates[0].split(",");
    if (ranges.length > 1) {
      let dateRange = [
        moment(ranges[0], "YYYY-MM-DD").toDate(),
        moment(ranges[1], "YYYY-MM-DD").toDate(),
      ];
      if (moment(dateRange[0]).isValid() && moment(dateRange[1]).isValid()) {
        onSelect([dateRange[0], dateRange[1]]);
      }
    }

    let range = formatRange(newDayRange);
    setInputValue(range);
    setShowDropdown(false);
  };

  const formatDate: (date: DayValue) => string = (date) => {
    const { day, month, year } = date;
    // Ensure day and month have leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    return `${year}-${formattedMonth}-${formattedDay}`;
  };
  const formatRange: (range: DayRange) => string = (range) => {
    const { from, to } = range;
    const formattedFrom = from ? formatDate(from) : "";
    const formattedTo = to ? formatDate(to) : "";
    return `${formattedFrom}  -  ${formattedTo}`;
  };
  // Parse the input value to extract the date range
  const parseInputValue = () => {
    const dates = inputValue.split(" - ");
    if (dates.length === 2) {
      if (moment(dates[0]).isValid() && moment(dates[1]).isValid()) {
        const [from, to] = dates.map((date) => {
          const [year, month, day] = date.split("-").map(Number);
          return { year, month, day };
        });
        return { from, to };
      } else {
        return { from: null, to: null };
      }
    }
    return { from: null, to: null };
  };

  useEffect(() => {
    const { from, to } = parseInputValue();
    setSelectedDayRange({ from, to });
  }, [inputValue]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        rangeRef.current &&
        e.target instanceof Node &&
        !rangeRef.current.contains(e.target)
      ) {
        setShowDropdown(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (value?.from && value?.to) {
      setInputValue(formatRange(value));
    } else {
      setInputValue("");
    }
  }, [value]);

  const handleRangePickerActions = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if ((e.target as HTMLDivElement)?.id === "clear-range") {
      setInputValue("");
      setSelectedDayRange({
        from: null,
        to: null,
      });
      onSelect([null, null]);
    } else {
      setShowDropdown(true);
    }
  };
  const myCustomLocale: Locale = {
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    weekDays: [
      { name: "Sunday", short: "sun", isWeekend: true },
      { name: "Monday", short: "mon" },
      { name: "Tuesday", short: "tue" },
      { name: "Wednesday", short: "wed" },
      { name: "Thursday", short: "thu" },
      { name: "Friday", short: "fri" },
      { name: "Saturday", short: "sat", isWeekend: true },
    ],
    weekStartingIndex: 0,
    getToday: (gregorianTodayObject: DayValue) => gregorianTodayObject,
    toNativeDate: (date: DayValue) =>
      new Date(date.year, date.month - 1, date.day),
    getMonthLength: (date: DayValue) =>
      new Date(date.year, date.month, 0).getDate(),
    transformDigit: (digit: CalendarDigit): CalendarDigit => digit,
    nextMonth: "Next Month",
    previousMonth: "Previous Month",
    openMonthSelector: "Open Month Selector",
    openYearSelector: "Open Year Selector",
    closeMonthSelector: "Close Month Selector",
    closeYearSelector: "Close Year Selector",
    defaultPlaceholder: "Select...",
    from: "from",
    to: "to",
    digitSeparator: ",",
    yearLetterSkip: 0,
    isRtl: false,
  };
  const closeRangePicker = () => {
    setShowDropdown(false);
  };

  // Add event listener for scroll
  useEffect(() => {
    const handleScroll = () => {
      closeRangePicker();
    };
    // Add scroll event listener
    window.addEventListener("wheel", handleScroll, { passive: true });

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, []);
  return (
    <div ref={rangeRef}>
      <div
        className="d-flex align-items-center justify-content-start range-picker-input-wrapper"
        style={{}}
        onClick={(e) => handleRangePickerActions(e)}
      >
        <Image
          src="/images/dashboard/calendar_icon.svg"
          alt="Calendar"
          className="position-absolute cal-img"
        />
        <input
          style={{ border: "none", paddingLeft: "2.3rem" }}
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Filter by date range"
          readOnly
        />
        {inputValue.length > 0 && (
          <div
            className="range-clear d-flex align-items-center justify-content-center cursor-pointer"
            id="clear-range"
          >
            x
          </div>
        )}
      </div>
      {showDropdown && (
        <Calendar
          calendarClassName={sameDaySelected ? "same-day" : ""}
          value={selectedDayRange}
          onChange={handleDayRangeChange}
          locale={myCustomLocale}
          colorPrimary="#1C324A"
          colorPrimaryLight="#E2F0FF"
          shouldHighlightWeekends={false}
          maximumDate={{
            day: new Date().getDate(),
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
          }}
          renderFooter={() => (
            <div className="custom-picker-footer">
              <button
                className="cancel-range-btn"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedDayRange({
                    from: null,
                    to: null,
                  });
                  onSelect([null, null]);
                  setShowDropdown(false);

                  setInputValue("");
                }}
              >
                Cancel
              </button>
              <button
                className="save-range-btn"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();

                  dateSelected(selectedDayRange);
                }}
              >
                Set date
              </button>
            </div>
          )}
        />
      )}
    </div>
  );
};

export default RangePicker;
