import React, { useEffect, useState } from "react";
import NavbarLayout from "../components/NavbarLayout";
import Header from "../components/Dashboard/Header";
import ActivityTime from "../components/Dashboard/ActivityTime";
import LineCharts from "../components/LineCharts/LineCharts";
import ProductivityChart from "../components/Dashboard/ProductivityChart";
import WorkedHours from "../components/Dashboard/WorkedHours";
import Idlehours from "../components/Dashboard/Idlehours";
import ProductiveDetails from "../components/Dashboard/ProductiveDetails";
import Screenshot from "../components/Dashboard/Screenshot";
import "../styles/dashboard.scss";
import "../styles/linechart.scss";
import { commonGetService } from "../utils/properties";
import { useAppSelector } from "../app/hooks";
import moment from "moment";
import { getCookie, handleFilterApi } from "../utils/properties";
import { useNavigate } from "react-router";
import Timelines from "../components/Dashboard/Timelines";
import TimeLineTable from "../components/TimelineCharts/TimeLineTable";
import { showToast } from "../features/Toaster/toastslice";
import { useAppDispatch } from "../app/hooks";

export interface ActivityTimes {
  productive_time: string;
  productive_percentage: string;
  unproductive_time: string;
  unproductive_percentage: string;
  neutral_time: string;
  neutral_percentage: string;
  total_time: string;
  total_active_user: number;
  total_active_user_percentage: string;
  idle_time?: string;
  idle_time_percentage: number;
}

export interface HoursTracked {
  label: string;
  total_time: number;
  dateRange: string;
}

export interface activityProps {
  activityDetails: ActivityTimes | undefined;
  isLoading: boolean;
}

// export const activityContext = React.createContext<ActivityTimes | null>(null);
export const activityContext = React.createContext<activityProps | null>(null);

const Dashboard = () => {
  const [activityDetails, setActivityDetails] = useState<ActivityTimes>();
  const [isToday, setIsToday] = useState<boolean>(true);
  const [isYesterday, setIsYesterday] = useState<boolean>(false);
  const [hoursTracked, setHoursTracked] = useState<HoursTracked[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChartLoading, setIsChartLoading] = useState<boolean>(false);

  const filterData = useAppSelector((state) => state.filterData);
  const { startDate, endDate, empDeviceId, filterType, organization, department } = filterData; // Destructuring the filterData state value
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // checkCookie();
    let checks = checkDate();
    getActivityDetails();

    if (!checks) {
      getHoursTracked();
    }
  }, [filterData, isToday, isYesterday]);

  // const checkCookie = () => {
  //   if (getCookie() === undefined) {
  //     navigate("/");
  //   }
  // };

  const checkDate = () => {
    let today = moment(new Date()).format("L");
    let yesterday = moment(moment().subtract(1, "day").toDate()).format("L");
    let fromDate = moment(startDate).format("L");
    let toDate = moment(endDate).format("L");
    setIsToday(today === fromDate && today === toDate);
    setIsYesterday(yesterday === fromDate && yesterday === toDate);
    return (
      (today === fromDate && today === toDate) ||
      (yesterday === fromDate && yesterday === toDate)
    );
  };

  const getActivityDetails = () => {
    setIsLoading(true);
    commonGetService(`/api/1.0/dashboard/trends?${handleFilterApi(startDate, endDate, filterType, organization, empDeviceId, department, false)}`).then((res) => {
      setIsLoading(false);
      if (res.status === 200) {
        setActivityDetails(res?.data);
      }
      else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  const getHoursTracked = () => {
    setIsChartLoading(true);
    commonGetService(
      `/api/1.0/dashboard/tracked/hours?${handleFilterApi(startDate, endDate, filterType, organization, empDeviceId, department, true)}`
    ).then((res) => {
      setIsChartLoading(false);
      if (res.status === 200) {
        setHoursTracked(res?.data);
      }
      else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  return (
    <>
      <activityContext.Provider value={{ activityDetails, isLoading } || null}>
        <NavbarLayout>
          <Header />
          <div className="data-container">
            <ActivityTime />
            {filterType === "employee" && (
              <div className={`line-chart-ctr timeline-chart-ctr`}>
                {/* <Timelines /> */}
                <TimeLineTable />
              </div>
            )}
            {!(isToday || isYesterday) && (
              <div
                className={`line-chart-ctr ${isToday || isYesterday ? "d-none" : "d-block"
                  } ${hoursTracked.length > 15 && "line-overflow"} ${filterType === "employee" && "timeline-to-chart-adjust"
                  } styled-scroll-hor`}
              >
                <LineCharts
                  trackedHours={hoursTracked}
                  chartLoading={isChartLoading}
                />
              </div>
            )}
            <div
              className={`${isToday || isYesterday
                ? filterType !== "employee" && "visibility-check"
                : ""
                }`}
            >
              <ProductiveDetails displayBarChart={isToday || isYesterday} />
            </div>
            {filterType !== "employee" && (
              <>
                <ProductivityChart />
                <WorkedHours />
                <Idlehours />
              </>
            )}
            <Screenshot />
          </div>
        </NavbarLayout>
      </activityContext.Provider>
    </>
  );
};

export default Dashboard;
