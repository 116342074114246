import React, { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import { userDetails } from "../../NavbarLayout";
import { useNavigate } from "react-router";

interface ProfileBadgeProps {
  userDetails: userDetails;
}

const ProfileBadge: React.FC<ProfileBadgeProps> = ({ userDetails }) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const { Name, picture, employee_id, role } = userDetails;
  const targetRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        targetRef.current &&
        e.target instanceof Node &&
        !targetRef.current.contains(e.target)
      ) {
        setShowDropdown(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      setShowDropdown(false);
    };
    // Add scroll event listener
    window.addEventListener("wheel", handleScroll, { passive: true });

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, []);

  const handleLogout = () => {
    document.cookie =
      "X-XSRF-TOKEN = null;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();
    window.location.href = "/";
    // navigate("/")
  };
  return (
    <>
      <div ref={targetRef} className="custom-profile-badge">
        <div
          className="profile-badge d-flex justify-content-betweeen align-items-center cursor-pointer"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <div className={picture ? "pro-pic" : "pro-pic fill-color-avatar"}>
            {picture ? (
              <Image src={picture} alt="Profile" />
            ) : (
              <span>{Name.charAt(0)}</span>
            )}
          </div>
          <div>
            <Image src="/images/dashboard/down_arrow.svg" alt="Down arrow" />
          </div>
        </div>

        {showDropdown && (
          <div className="badge-dropdown">
            <p>Profile</p>
            <div className="user-block">
              <div
                className={picture ? "pro-pic" : "pro-pic fill-color-avatar"}
              >
                {picture ? (
                  <Image src={picture} alt="Profile" />
                ) : (
                  <span>{Name.charAt(0)}</span>
                )}
                <div className="cam-icon-holder cursor-pointer">
                  <Image src="/images/dashboard/cam_icon.svg" alt="Profile" />
                </div>
              </div>
              <p className="user-name">{Name}</p>
              <p className="user-id">
                <span>{employee_id}</span> {role}
              </p>
            </div>

            <div className="badge-info cursor-pointer">
              <div className="d-flex align-items-center ">
                <span className="profile-avatar-style">
                  <Image
                    src="/images/dashboard/add-friend 1.svg"
                    alt="account details"
                  />
                </span>
                <span>Account Details</span>
              </div>
              <div
                className="d-flex align-items-center"
                onClick={() => handleLogout()}
              >
                <span className="profile-avatar-style">
                  <Image src="/images/dashboard/power.svg" alt="sign out" />
                </span>
                <span>Sign Out</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileBadge;
