import React, { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
interface Alert {
  alertLabel: string;
  alertTime: string;
  alertMsg: string;
  unread: boolean;
}
interface Notifications {
  notificationDay: string;
  messages: Alert[];
}

const NotificationDropdown = () => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const targetRef = useRef<HTMLDivElement | null>(null);

  const Data: Notifications[] = [
    {
      notificationDay: "Today",
      messages: [
        {
          alertLabel: "Profile updated",
          alertTime: "10:00",
          alertMsg:
            "Your Profile has been updated Your Profile has been updated Your Profile has been updated",
          unread: true,
        },
        {
          alertLabel: "Profile",
          alertTime: "10:00",
          alertMsg: "Your leave has been approved",
          unread: true,
        },
      ],
    },
    {
      notificationDay: "Yesterday",
      messages: [
        {
          alertLabel: "Extended Break",
          alertTime: "10:00",
          alertMsg: "Your Profile has been updated",
          unread: false,
        },
        {
          alertLabel: "Leave Approved",
          alertTime: "10:00",
          alertMsg: "Your leave has been approved",
          unread: false,
        },
      ],
    },
  ];

  const extractFirstLetters = (sentence = "") => {
    if (sentence !== "") {
      const words = sentence.split(" ");
      return (
        words[0]?.charAt(0)?.toLocaleUpperCase() +
        (words[1] ? words[1]?.charAt(0)?.toLocaleUpperCase() : "")
      );
    } else {
      return;
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        targetRef.current &&
        e.target instanceof Node &&
        !targetRef.current.contains(e.target)
      ) {
        setShowDropdown(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      setShowDropdown(false);
    };
    // Add scroll event listener
    window.addEventListener("wheel", handleScroll, { passive: true });

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, []);

  return (
    <>
      <div ref={targetRef} className="custom-notification-dropdown">
        <div
          className="note-box cursor-pointer"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <Image
            src="/images/dashboard/notification_icon.svg"
            alt="Notification"
            className="not-icon"
          />
          <span className="d-inline-block">12</span>
        </div>
        {showDropdown && (
          <div className="notification-dropdown">
            <div className="d-flex justify-content-between">
              <p className="alert-label">Notification</p>

              <p className="read-all cursor-pointer">Mark All As Read</p>
            </div>
            {Data.length > 0 &&
              Data.map((item, index) => {
                return (
                  <div key={index} className="notification-items ">
                    <div className="d-flex justify-content-between gap-hr">
                      <p>{item.notificationDay}</p>
                      <hr />
                    </div>
                    {item?.messages.length > 0 &&
                      item?.messages.map((message) => (
                        <div className="alert-Msgs d-flex ">
                          <div
                            className={`pro-pic fill-color-avatar d-flex align-items-center justify-content-center ${
                              message?.unread && "notificationunread"
                            }`}
                          >
                            <span>
                              {extractFirstLetters(message?.alertLabel)}
                            </span>
                          </div>
                          <div className="w-90">
                            <div className="d-flex justify-content-between align-items-center msg-title-area">
                              <p className="alert-label">
                                {message?.alertLabel}
                              </p>
                              <p className="alert-time">{message?.alertTime}</p>
                            </div>
                            <p className="msg-area">{message?.alertMsg}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                );
              })}
            <button className="w-100 view-all">View All</button>
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationDropdown;
