import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Image, ProgressBar } from "react-bootstrap";
import LiveEmPloyeeList from "./LiveEmPloyeeList";
import WorkedHoursSkeleton from "../Skeletons/WorkedHoursSkeleton";
import { commonGetService, handleFilterApi } from "../../utils/properties";
import { useAppSelector,useAppDispatch } from "../../app/hooks";
import moment from "moment";
import { showToast } from "../../features/Toaster/toastslice";

interface UserInfo {
  image: string;
  email: string;
  department: string;
  designation: string;
  employee_id: number;
  employee_code: string;
  employee_name: string;
  device_id: string;
}

interface IdleHours {
  deviceId: string;
  total_time: number;
  idle_time: string;
  idle_time_percentage: number;
  user_info: UserInfo;
}

const Idlehours = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [idleHoursList, setIdleHoursList] = useState<IdleHours[]>([]);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const filterData = useAppSelector((state) => state.filterData);
  const { startDate, endDate, empDeviceId, filterType, organization, department } = filterData; // Destructuring the filterData state value
  const targetRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.25,
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInitialLoad(false);
          getIdleHoursList();

          observer.disconnect();
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [filterData]);

  // useEffect(() => {
  //   if (!initialLoad) {
  //     getIdleHoursList();
  //   }
  // }, [filterData])

  const getIdleHoursList = () => {
    setIsLoading(true);
    commonGetService(
      `/api/1.0/dashboard/users/most/idle?${handleFilterApi(startDate, endDate, filterType, organization, empDeviceId, department, true)}`
    ).then((res) => {
      setIsLoading(false);
      if (res.status === 200) {
        setIdleHoursList(res?.data);
      }
      else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  return (
    <div className="idle-container" ref={targetRef}>
      <Row className="m-0">
        <Col className="high-idle d-flex flex-column" lg>
          <div className="d-flex justify-content-between common-card-title">
            <h5>Highest % idle minutes</h5>
            <p className="cursor-pointer">Activity summary</p>
          </div>
          {isLoading ? (
            <WorkedHoursSkeleton count={5} />
          ) : (
            <>
              {idleHoursList.length ? (
                idleHoursList?.map((res, ind) => (
                  <div
                    className="d-flex flex-row align-items-center idle-emp-dts w-100"
                    key={ind}
                  >
                    <div className="img-ctr">
                      {res?.user_info?.image ?
                        <Image
                          src={res?.user_info?.image}
                          alt={res?.user_info?.employee_name}
                        /> :
                        <div className="img-avatar d-flex justify-content-center align-items-center">
                          <p>{res?.user_info?.employee_name?.charAt(0)}</p>
                        </div>
                      }
                    </div>
                    <div className="name-ctr">
                      <p>{res?.user_info?.employee_name}</p>
                    </div>
                    <div className="pg-bar-ctr">
                      <ProgressBar
                        now={res?.idle_time_percentage}
                        variant={ind === 0 ? "red-one" : ind === 1 ? "red-two" : ind === 2 ? "red-three" : ind === 3 ? "red-four" : "red-five"}
                      />
                    </div>
                    <div className="pg-bar-ptg">
                      <p>{res?.idle_time_percentage}%</p>
                    </div>
                    <div className="time-ctr">
                      <p>{res?.idle_time}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="d-flex flex-row  w-100 no-data-row">
                  <div className="no-data-image-wrapper no-user-image">
                    <Image
                      src="/images/dashboard/user_icon.png"
                      alt="No Data Found"
                    />
                  </div>
                  <p>No users to show</p>
                </div>
              )}
            </>
          )}
        </Col>
        <Col className="high-idle" lg>
          <LiveEmPloyeeList />
        </Col>
      </Row>
    </div>
  );
};

export default Idlehours;
