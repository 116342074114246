import React, { useEffect } from "react";
import Header from "../components/Dashboard/Header";
import NavbarLayout from "../components/NavbarLayout";
import ScreenshotsComponent from "../components/Screenshots/Screenshots";
import "../styles/screenshots.scss"
import { useNavigate } from "react-router";
import {
    getCookie
} from "../utils/properties";
const Screenshots = () => {
    const navigate = useNavigate();

    // useEffect(() => {
    //     checkCookie();
    // }, []);
    // const checkCookie = () => {
    //     if (getCookie() === undefined) {
    //         navigate("/");
    //     }
    // };
    return (
        <div className="screenshots-wrapper-page">
            <NavbarLayout>
                <Header />
                <div className="data-container">
                    <ScreenshotsComponent />
                </div>
            </NavbarLayout>
        </div>
    );
};

export default Screenshots;
