import React from "react";
import { Modal, Button, Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import "../../styles/Managers/managerList.scss";

interface componentProps {
  modalShow: boolean;
  handleModal: (val: boolean, confirm: string) => void;
}

const DeletePopup: React.FC<componentProps> = ({ modalShow, handleModal }) => {
  const location = useLocation();
  let path = location.pathname.split("/");

  return (
    <Modal
      show={modalShow}
      size="lg"
      onHide={() => handleModal(false, "No")}
      centered
      className="del-alert"
    >
      <Modal.Body>
        <div className="modal-container">
          <div className="d-flex flex-column align-items-center">
            <div className="cls-icon">
              <Image src="/images/roles/danger_delete.svg" />
            </div>
            <div className="modal-head">
              {path[1] !== "roles" ? (
                <h5>Delete Manager</h5>
              ) : (
                <h5>Delete Role</h5>
              )}
            </div>
            <div className="modal-desc">
              {path[1] !== "roles" ? (
                <p>Are you sure you want to delete this Manager ?</p>
              ) : (
                <p>Are you sure you want to delete this role ?</p>
              )}
            </div>
            <div className="choose-btns d-flex flex-row">
              <div>
                <Button
                  variant="outline-dark"
                  className="cancel-btn"
                  onClick={() => handleModal(false, "No")}
                >
                  Cancel
                </Button>
              </div>
              <div className="ms-3">
                <Button
                  className="remove-btn"
                  onClick={() => handleModal(false, "Yes")}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
          <div
            className="close-btn cursor-pointer"
            onClick={() => handleModal(false, "No")}
          >
            <Image src="/images/roles/modal_close_icon.svg" alt="Close" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeletePopup;
