import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Toast from "./Toast";
import { RootState } from "../../../app/store";
import { hideToast } from "../../../features/Toaster/toastslice";

const ToastContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { message, visible, status } = useSelector(
    (state: RootState) => state.toast
  );

  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        dispatch(hideToast());
      }, 3000); // Adjust the duration as needed

      return () => clearTimeout(timer);
    }
  }, [visible, dispatch]);

  return visible ? <Toast message={message} status={status} /> : <></>;
};

export default ToastContainer;
