import React, { useEffect, useRef, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text,
  ResponsiveContainer,
} from "recharts";
import "../../styles/linechart.scss";
import moment from "moment";
import { commonGetService } from "../../utils/properties";
import { useAppSelector } from "../../app/hooks";
import { Spinner } from "react-bootstrap";

interface DataItem {
  name: string;
  uv: number;
  time: number;
  dates: string;
}

interface HoursTracked {
  label: string;
  total_time: number;
}
interface HoursTracked {
  label: string;
  total_time: number;
  dateRange: string;
}

interface LineChartsProps {
  trackedHours: HoursTracked[];
  chartLoading: boolean
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  coordinate?: { x: number; y: number };
  xAxisTicks: { coordinate: number }[];
  isAnimationActive: boolean;
}

const LineCharts: React.FC<LineChartsProps> = ({ trackedHours, chartLoading }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [rightMost, setRightMost] = useState<boolean>(false);
  const [hoursTracked, setHoursTracked] = useState<HoursTracked[]>([]);
  const [xAxisTicks, setXAxisTicks] = useState<{ coordinate: number }[]>([]);
  const dateRange = useAppSelector((state) => state.filterData);
  const { startDate, endDate } = dateRange;

  const data: DataItem[] = [
    {
      name: "S",
      uv: 0,
      time: 0,
      dates: "Sun, Mar 22",
    },
    {
      name: "M",
      uv: 0.5,
      time: 0.5 * 60,
      dates: "Mon, Mar 23",
    },
    {
      name: "T",
      uv: 2,
      time: 2 * 60,
      dates: "Tue, Mar 24",
    },
    {
      name: "W",
      uv: 1,
      time: 1 * 60,
      dates: "Wed, Mar 25",
    },
    {
      name: "T",
      uv: 1,
      time: 1 * 60,
      dates: "Thu, Mar 26",
    },
    {
      name: "F",
      uv: 2,
      time: 2 * 60,
      dates: "Fri, Mar 27",
    },
    {
      name: "S",
      uv: 0,
      time: 0,
      dates: "Sat, Mar 28",
    },
  ];

  const CustomLegend: React.FC = () => (
    <div className="w-100 flex-center">
      <span>Days</span>
    </div>
  );

  const CustomDot1: React.FC<{ cx?: number; cy?: number; payload?: any }> = ({
    cx,
    cy,
    payload,
  }) => {
    if (payload && payload.total_time !== undefined) {
      return (
        <svg width={10} height={10} x={cx ? cx - 5 : 0} y={cy ? cy - 5 : 0}>
          {/* Outer circle */}
          <circle cx={5} cy={5} r={5} fill="rgba(76, 175, 80, 0.85)" />
          {/* Inner circle representing the checked state */}
          <circle cx={5} cy={5} r={2} fill="white" />
        </svg>
      );
    } else {
      return null;
    }
  };

  const CustomTick: React.FC<{ x?: number; y?: number; payload?: any }> = ({
    x,
    y,
    payload,
  }) => {
    return (
      <Text
        x={x}
        y={y}
        fontSize={14}
        textAnchor={x && x <= 80 ? "start" : "middle"}
      >
        {payload.value}
      </Text>
    );
  };

  const CustomTooltip: React.FC<CustomTooltipProps> = ({
    active,
    payload,
    coordinate,
    xAxisTicks,
  }) => {
    if (active && payload && payload.length) {
      const { x, y } = coordinate || { x: 0, y: 0 };

      let closestRightTickIndex = xAxisTicks.length - 1;
      let maxDistance = Math.abs(
        x - xAxisTicks[xAxisTicks.length - 1].coordinate
      );
      for (let i = xAxisTicks.length - 2; i >= 0; i--) {
        const distance = Math.abs(x - xAxisTicks[i].coordinate);
        if (distance < maxDistance) {
          maxDistance = distance;
          closestRightTickIndex = i;
        } else {
          break;
        }
      }

      const closestTickCoordinateRight =
        xAxisTicks[closestRightTickIndex].coordinate;

      const isRightmostDot = () => {
        if (active && payload && payload.length && coordinate) {
          const x = closestTickCoordinateRight;
          if (xAxisTicks.length > 0) {
            return x === xAxisTicks[xAxisTicks.length - 1].coordinate;
          }
        }
        return false;
      };

      setRightMost(isRightmostDot());

      return (
        <>
          <div className="flex-between tooltip-pill">
            <span>
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.9974 0.658203C4.84367 0.658203 3.71586 1.00032 2.75657 1.6413C1.79729 2.28227 1.04961 3.19331 0.608101 4.25922C0.16659 5.32512 0.0510705 6.49801 0.276151 7.62956C0.501231 8.76112 1.0568 9.80052 1.87261 10.6163C2.68842 11.4321 3.72782 11.9877 4.85937 12.2128C5.99093 12.4379 7.16382 12.3223 8.22972 11.8808C9.29562 11.4393 10.2067 10.6917 10.8476 9.73236C11.4886 8.77308 11.8307 7.64526 11.8307 6.49154C11.829 4.94499 11.2138 3.46229 10.1202 2.36871C9.02665 1.27513 7.54395 0.659979 5.9974 0.658203ZM8.06981 8.18L5.73647 7.01333C5.63958 6.96487 5.5581 6.89039 5.50115 6.79823C5.44421 6.70607 5.41405 6.59987 5.41406 6.49154V3.57487C5.41406 3.42016 5.47552 3.27179 5.58492 3.16239C5.69432 3.05299 5.84269 2.99154 5.9974 2.99154C6.15211 2.99154 6.30048 3.05299 6.40988 3.16239C6.51927 3.27179 6.58073 3.42016 6.58073 3.57487V6.13092L8.59166 7.13635C8.73005 7.20556 8.83529 7.3269 8.88422 7.47369C8.93314 7.62049 8.92175 7.7807 8.85255 7.9191C8.78335 8.0575 8.66201 8.16273 8.51521 8.21166C8.36842 8.26059 8.2082 8.2492 8.06981 8.18Z"
                  fill="#969696"
                />
              </svg>
            </span>
            <span className="m-l-10">
              {payload[0]?.payload.total_time + "  Hr"}
            </span>
          </div>
        </>
      );
    }

    return null;
  };

  const Legend2: React.FC = () => (
    <div className="flex-start align-left">
      <h4 className="heading-mb">Hours Tracked</h4>
      &nbsp; &nbsp;
      <div>
        <span className="info_icon_style">{hoursTracked[0]?.dateRange}</span>
      </div>
    </div>
  );

  useEffect(() => {
    if (chartRef.current) {
      const chartWidth = chartRef.current.offsetWidth;
      const numDataPoints = data.length;
      const tickSpacing = chartWidth / (numDataPoints - 1);

      const newTicks = data.map((entry, index) => ({
        coordinate: index * tickSpacing,
      }));

      setXAxisTicks(newTicks);
    }

    let a = [...trackedHours].reverse();
    setHoursTracked([...a]);
    return () => {
      setHoursTracked([]);
    };
  }, [trackedHours]);

  const containerWidth = Math.max(800, hoursTracked.length * 100); // Set a minimum width and adjust based on the number of data points

  return (
    <>
      <Legend2 />
      <ResponsiveContainer
        width={hoursTracked.length > 15 ? containerWidth : "100%"}
        // height={350}
        ref={chartRef}
        className={"linecharts-timetracker"}
      >
        {chartLoading ?
        <div className="d-flex justify-content-center align-items-center h-100 crt-loader">
          <Spinner animation="border"/> 
        </div>:
          <LineChart
            data={hoursTracked}
            margin={{
              top: 55,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          // overflowX="auto"
          >
            <CartesianGrid
              strokeDasharray="3 3"
              horizontal={true}
              vertical={false}
              strokeWidth={1}
            />
            <XAxis
              dataKey="label"
              axisLine={{
                stroke: "#E5E5EF",
                strokeWidth: 3, // Adjust the stroke width as needed
                fontSize: "14px",
              }}
              tickLine={false}
              tick={<CustomTick />}
              tickMargin={15}
              minTickGap={10}
            />
            <YAxis
              orientation="left"
              axisLine={false}
              tick={true}
              tickLine={false}
              padding={{ top: 0, bottom: 0 }}
              tickCount={5}
              allowDecimals={false}
              tickMargin={15}
            />

            <Tooltip
              cursor={false}
              content={
                <CustomTooltip
                  xAxisTicks={xAxisTicks}
                  isAnimationActive={false}
                />
              }
              wrapperStyle={{ left: rightMost ? "3rem" : "-3rem" }}
            />
            <Legend content={<CustomLegend />} verticalAlign="bottom" />
            <Line
              type="linear"
              dataKey="total_time"
              stroke="rgba(76, 175, 80, 0.85)"
              dot={<CustomDot1 />}
              activeDot={<CustomDot1 />}
              strokeWidth={3}
            />
          </LineChart>
        }
      </ResponsiveContainer>
    </>
  );
};

export default LineCharts;
